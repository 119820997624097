import React from "react";
import { AlternateEmailRounded } from "@material-ui/icons";
import { useDispatch } from "react-redux";

const ContextItem = (props) => {
  const { context, dispatchFunction, ...rest } = props;

  const dispatch = useDispatch();

  const onClick = (event) => {
    if (dispatchFunction) {
      event.stopPropagation();
      dispatch(dispatchFunction(context));
    }
  };

  return context ? (
    <span
      {...rest}
      className={`RightMargin Pointer noselect nowrap ${rest.className ?? ""}`}
      onClick={onClick}
    >
      <AlternateEmailRounded style={{ fontSize: 18 }} />
      {context.name}
    </span>
  ) : null;
};

ContextItem.propTypes = {
  // context: PropTypes.object.isRequired,
};

export default ContextItem;
