import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import { useInputValue } from "../../hooks/useInputValue";
import { showAlert, hideAlert } from "../../redux/alertSlice";
import { Alert } from "../components/Alert";
import { getErrorMessage } from "../../utils/api";

const stateInitial = 0;
const stateEmailSent = 1;
const stateSuccess = 2;

export const RecoveryForm = ({ deviceId }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState(stateInitial);

  const emailInput = useInputValue("");
  const passwordInput = useInputValue("");
  const pinInput = useInputValue("");

  const makeRequest = () => {
    dispatch(hideAlert());

    const authData = {
      email: emailInput.value(),
      pass: state === stateEmailSent ? passwordInput.value() : null,
      code: state === stateEmailSent ? pinInput.value() : null,
    };
    const headers = new Headers({
      Origin2: "web@" + deviceId,
    });

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/recover`, {
      method: "POST",
      mode: "cors",
      headers: headers,
      body: JSON.stringify(authData),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        console.log("Recover response", response);
        if (response.error) {
          dispatch(
            showAlert({ text: getErrorMessage(response.error), type: "danger" })
          );
        } else {
          if (state === stateInitial) {
            setState(stateEmailSent);
          } else {
            setState(stateSuccess);
          }
        }
      })
      .catch((error) => {
        dispatch(showAlert({ text: getErrorMessage(""), type: "danger" }));
      });
  };

  function handleSubmit(event) {
    event.preventDefault();
    makeRequest();
  }

  function validateForm() {
    switch (state) {
      case stateInitial:
        return emailInput.value().length > 0;
      case stateEmailSent:
        return passwordInput.value().length > 0 && pinInput.value().length > 0;
      case stateSuccess:
        return true;
      default:
        return false;
    }
  }

  const email = () => {
    return (
      <FormGroup controlId="email" bssize="large">
        <FormLabel>Email</FormLabel>
        <FormControl
          autoFocus
          type="email"
          {...emailInput.bind}
          readOnly={state !== stateInitial}
        />
        {message()}
      </FormGroup>
    );
  };

  const message = () => {
    const text = (text) => <div className="mt-2 text-center">{text}</div>;
    switch (state) {
      case stateEmailSent:
        return text("Verification code was sent to your email");
      case stateSuccess:
        return text("Password changed. You can now login");
      default:
        return null;
    }
  };

  const body = () => {
    if (state === stateEmailSent) {
      return (
        <div>
          <FormGroup controlId="password" bssize="large">
            <FormLabel>New password</FormLabel>
            <FormControl {...passwordInput.bind} type="password" />
          </FormGroup>
          <FormGroup controlId="pin" bssize="large">
            <FormLabel>Verification code</FormLabel>
            <FormControl {...pinInput.bind} maxLength={10} />
          </FormGroup>
        </div>
      );
    } else {
      return null;
    }
  };

  const button = () => {
    if (state === stateSuccess) {
      return null;
    } else {
      return (
        <Button
          className="mb-1"
          block
          bssize="large"
          disabled={!validateForm()}
          type="error"
        >
          {state === stateInitial ? "Recover" : "Change password"}
        </Button>
      );
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      {email()}
      {body()}
      {button()}
      <div className="mt-2">
        <Alert />
      </div>
    </Form>
  );
};
