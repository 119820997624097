import tinycolor from "tinycolor2";
import firebase from "firebase/app";
import Constants from "./constants";

var extraCounter = 0;

export const getExtraCounter = () => ++extraCounter;

export const findByCloudId = (items, cloudId) =>
  items ? items.find((item, _, __) => item.cloudId === cloudId) : null;

export const findByCloudIds = (items, cloudIds) =>
  items && cloudIds
    ? items.filter((item) => cloudIds.includes(item.cloudId))
    : [];

export const removeItemFromArray = (items, item, predicate) => {
  var i = 0;
  while (i < items.length) {
    if (predicate(items[i], item)) {
      items.splice(i, 1);
    } else {
      ++i;
    }
  }
  return items;
};

export const isColorLight = (color) => {
  return tinycolor(color).getLuminance() > 0.85;
};

export const isDevelopment = () => process.env.NODE_ENV === "development";

export const logEvent = (event) => {
  if (!isDevelopment()) {
    firebase.analytics().logEvent(event);
  }
};

export const rankSort = (a, b) => {
  const rank1 = a.rank ?? "";
  const rank2 = b.rank ?? "";

  if (rank1 < rank2) {
    return -1;
  }
  if (rank1 > rank2) {
    return 1;
  }
  return 0;
};

export const getRankBetween = (first, second) => {
  var firstRank = first ?? Constants.rank_start;
  var secondRank = second ?? Constants.rank_end;

  while (firstRank.length != secondRank.length) {
    if (firstRank.length > secondRank.length) {
      secondRank += "z";
    } else {
      firstRank += "a";
    }
  }

  if (firstRank == secondRank) {
    secondRank += "r";
  } else if (firstRank > secondRank) {
    [firstRank, secondRank] = [secondRank, firstRank];
  }

  var firstCodes = string2Bin(firstRank);
  var secondCodes = string2Bin(secondRank);

  var difference = 0;
  for (var i = firstCodes.length - 1; i >= 0; i--) {
    var firstCode = firstCodes[i];
    var secondCode = secondCodes[i];

    if (secondCode < firstCode) {
      secondCode += Constants.alphabet_size;
      secondCodes[i - 1] -= 1;
    }

    var powRes = Math.pow(Constants.alphabet_size, firstRank.length - i - 1);
    difference += (secondCode - firstCode) * powRes;
  }

  var newElement = "";
  if (difference <= 1) {
    /// add middle char from alphabet
    newElement = firstRank + "r";
  } else {
    difference = Math.floor((difference * 2) / 3);

    var offset = 0;
    for (var index = 0; index < firstCodes.length; index++) {
      /// formula: x = difference / (size^place - 1) % size;
      /// i.e. difference = 110, size = 10, we want place 2 (middle),
      /// then x = 100 / 10^(2 - 1) % 10 = 100 / 10 % 10 = 11 % 10 = 1
      var diffInSymbols =
        Math.floor(difference / Math.pow(Constants.alphabet_size, index)) %
        Constants.alphabet_size;

      var newElementCode =
        firstRank.charCodeAt(secondRank.length - index - 1) +
        diffInSymbols +
        offset;
      offset = 0;

      /// if newElement is greater then 'z'
      if (newElementCode > "z".charCodeAt(0)) {
        offset++;
        newElementCode -= Constants.alphabet_size;
      }

      newElement += String.fromCharCode(newElementCode);
    }

    newElement = newElement.split("").reverse().join("");
  }

  return newElement;
};

function string2Bin(str) {
  var result = [];
  for (var i = 0; i < str.length; i++) {
    result.push(str.charCodeAt(i));
  }
  return result;
}

export const openUrl = (link) => {
  window.open(link, "_blank");
};
