import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { App } from "./App";
import store from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import firebase from "firebase/app";
import "firebase/analytics";
import { isDevelopment } from "./utils/utils";

if (!isDevelopment()) {
  console.log = () => {};

  var firebaseConfig = {
    apiKey: "AIzaSyBVY9xPRe3evi3DYX5tzvbwFgZ7MK5WMBI",
    projectId: "blitz-af27b",
    // messagingSenderId: "419174467846",
    appId: "1:419174467846:web:2eb815905e8d21ec8d7687",
    measurementId: "G-6V3PXHXME0",
  };
  firebase.initializeApp(firebaseConfig);
}

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
