import React, { useState } from "react";
import PropTypes from "prop-types";
import reactCSS from "reactcss";
import { useDispatch } from "react-redux";
import { DeleteRounded, CheckRounded } from "@material-ui/icons";
import { useInputValue } from "../../../hooks/useInputValue";
import { filterNotifyEntityDeleted } from "../../../redux/filterSlice";
import { Circle } from "../Circle";
import {
  setAddingEntity,
  setEditingEntity,
} from "../../../redux/appStateSlice";
import Constants from "../../../utils/constants";
import { GithubPicker } from "react-color";

const EditTagItem = (props) => {
  const { entity, dispatchDoneFunction, ...rest } = props;
  const dispatch = useDispatch();

  const nameInput = useInputValue(entity.name);
  const [tagColor, setTagColor] = useState(entity.color);
  const [isShowingPicker, setShowingPicker] = useState(false);

  const onClick = (event) => {
    event.stopPropagation();
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      finishEditingEntity();
    }
  };

  const onKeyUp = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation();
      if (entity.objectId) {
        dispatch(setEditingEntity(null));
      } else {
        dispatch(setAddingEntity(null));
      }
    }
  };

  const finishEditingEntity = () => {
    dispatch(
      dispatchDoneFunction({
        ...entity,
        name: nameInput.value(),
        color: tagColor,
      })
    );
    if (!entity.objectId) {
      dispatch(setAddingEntity(null));
    } else {
      dispatch(setEditingEntity(null));
    }
  };

  const onFocus = () => {
    //clear previously entered and not confirmed value
    nameInput.setValue(entity.name);
  };

  const styles = reactCSS({
    default: {
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  return (
    <div>
      <div
        {...rest}
        className={`RightMargin Pointer noselect nowrap d-flex ${
          rest.className ?? ""
        }`}
        onClick={onClick}
      >
        <Circle
          className="mr-2"
          color={tagColor}
          onClick={(event) => setShowingPicker(!isShowingPicker)}
        />

        <input
          {...nameInput.bind}
          className="w-100 mr-1"
          type="text"
          autoFocus
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          maxLength="30"
        />

        <div className="ml-auto">
          <DeleteRounded
            className="mr-1"
            onClick={(e) => {
              e.stopPropagation();
              if (entity.cloudId) {
                dispatch(dispatchDoneFunction({ ...entity, deleted: true }));
                dispatch(filterNotifyEntityDeleted(entity));
              }
              dispatch(setAddingEntity(null));
            }}
          />
          <CheckRounded
            onClick={(e) => {
              e.stopPropagation();
              finishEditingEntity();
            }}
          />
        </div>
      </div>
      {isShowingPicker ? (
        <div style={styles.popover}>
          <div
            style={styles.cover}
            onClick={(event) => setShowingPicker(false)}
          />
          <GithubPicker
            color={tagColor}
            onChangeComplete={(color, event) => {
              setTagColor(color.hex);
              setShowingPicker(false);
            }}
            width="190px"
            colors={Constants.default_colors}
          />
        </div>
      ) : null}
    </div>
  );
};

EditTagItem.propTypes = {
  entity: PropTypes.object.isRequired,
};

export default EditTagItem;
