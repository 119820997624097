const DateRange = {
  all: 1,
  starred: 2,
  overdue: 3,
  today: 4,
  tomorrow: 5,
  next7Days: 6,
  later: 7,
  noDate: 8,
  completed: 9,
};

export default DateRange;
