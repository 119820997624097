import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InputGroup,
  FormControl,
  Form,
  DropdownButton,
  ButtonGroup,
} from "react-bootstrap";
import moment from "moment";
import DatePicker from "../DatePicker";

import { updateTask } from "../../../redux/dataSlice";
import { selectPrefsAccent } from "../../../redux/prefsSlice";
import {
  RepeatEndType,
  RepeatIntervalType,
  getNextOccurrenceAfter,
  adjustMoment,
} from "../../../utils/repeat";
import { formatDate, formatDateDb } from "../../../utils/dateUtils";
import constants from "../../../utils/constants";
import Switch from "react-switch";

const newRepeat = {
  interval: 1,
  intervalType: RepeatIntervalType.DAY,
  weekDays: "",
  lastDayOfMonth: false,
  endType: RepeatEndType.NEVER,
  endAfter: 21,
  endOn: null,
};

const weekRow = (weekDays, onChange) => {
  const now = moment().startOf("week");

  return (
    <div className="d-flex justify-content-between mt-2">
      {[0, 1, 1, 1, 1, 1, 1].map((element, index) => {
        now.add(element, "d");
        const day = index + 1;
        // const day = now.day();

        return (
          <div
            className={`px-1 text-center Pointer CalendarDay__default ${
              weekDays.includes(day) ? "CalendarDay__selected" : ""
            }`}
            key={"day_" + day}
            onClick={() => {
              if (weekDays.includes(day)) {
                onChange(weekDays.replace(day, ""));
              } else {
                onChange(weekDays + day);
              }
            }}
          >
            {now.format("dd")}
          </div>
        );
      })}
    </div>
  );
};

const defaultWeekdays = (taskDueDate) =>
  adjustMoment(moment(taskDueDate)).toString();

const TaskRepeat = ({ task }) => {
  const accent = useSelector(selectPrefsAccent);

  const dispatch = useDispatch();

  const endOn = task.repeat?.endOn
    ? moment(task.repeat.endOn)
    : moment().add(1, "M");

  const repeatWeekdays = task.repeat?.weekDays?.length
    ? task.repeat.weekDays
    : defaultWeekdays(task.dateDue);

  const [isIntervalValid, setIntervalValid] = useState(true);
  const [intervalValue, setIntervalValue] = useState(
    task.repeat?.interval ?? 1
  );

  const [isEndAfterValid, setEndAfterValid] = useState(true);
  const [endAfterValue, setEndAfterValue] = useState(
    task.repeat?.endAfter ?? 1
  );

  const repeatPreview = (task, repeat) => {
    var now = moment(task.dateDue);

    var result = formatDate(now);
    var occurrences = 0;
    const isEndAfterOccerenceds =
      repeat.endType === RepeatEndType.AFTER_OCCURENCES;

    for (var i = 1; i <= constants.repeat_next_preview; i++) {
      now = getNextOccurrenceAfter(repeat, now);

      if (
        !now ||
        (isEndAfterOccerenceds && occurrences >= repeat.endAfter - 1)
      ) {
        break;
      } else if (i === constants.repeat_next_preview) {
        result += ", ...";
      } else {
        result += ", " + formatDate(now);
      }

      if (isEndAfterOccerenceds) {
        occurrences++;
      }
    }
    return result;
  };

  const doUpdateTask = (repeat) => {
    const dateDue = task.dateDue ?? formatDateDb(moment());
    dispatch(
      updateTask({
        ...task,
        dateDue: dateDue,
        repeat: repeat ? { ...repeat, originalDueDate: dateDue } : null,
      })
    );
  };

  const onIntervalTypeChanged = (type) => {
    doUpdateTask({ ...task.repeat, intervalType: type });
  };

  const onEndTypeChanged = (type) => {
    doUpdateTask({ ...task.repeat, endType: type });
  };

  return (
    <div className="mx-3 mt-1 repeat noselect">
      <div
        className={`d-flex align-items-center mt-3 ${
          task.repeat ? "mb-2" : ""
        }`}
      >
        <div>Repeat</div>
        <Switch
          className="ml-auto"
          onChange={(checked) => {
            doUpdateTask(
              checked
                ? {
                    ...newRepeat,
                    weekDays: defaultWeekdays(
                      task.dateDue ?? formatDateDb(moment())
                    ),
                  }
                : null
            );
          }}
          uncheckedIcon={null}
          checkedIcon={null}
          height={20}
          width={36}
          onColor={accent}
          checked={task.repeat ? true : false}
        />
      </div>
      {task.repeat ? (
        <div className="mt-1">
          <div className="d-flex align-items-start">
            <div className="mr-4 mt-1">Every</div>
            <div className="d-flex ml-1 flex-column">
              <InputGroup size="sm">
                <FormControl
                  value={intervalValue}
                  placeholder="1"
                  type="number"
                  isInvalid={!isIntervalValid}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value);
                    setIntervalValue(newValue);
                    if (newValue > 0 && newValue <= 999) {
                      setIntervalValid(true);
                      doUpdateTask({ ...task.repeat, interval: newValue });
                    } else if (isIntervalValid) {
                      doUpdateTask({ ...task.repeat, interval: 1 });
                      setIntervalValid(false);
                    }
                  }}
                />
                <Form.Control
                  as="select"
                  value={task.repeat.intervalType ?? RepeatIntervalType.DAY}
                  onChange={(e) => onIntervalTypeChanged(e.target.value)}
                >
                  <option value={RepeatIntervalType.DAY}>day</option>
                  <option value={RepeatIntervalType.WEEK}>week</option>
                  <option value={RepeatIntervalType.MONTH}>month</option>
                  <option value={RepeatIntervalType.YEAR}>year</option>
                </Form.Control>
              </InputGroup>
              {task.repeat.intervalType === RepeatIntervalType.WEEK
                ? weekRow(repeatWeekdays, (weekDays) =>
                    doUpdateTask({ ...task.repeat, weekDays: weekDays })
                  )
                : null}
              {task.repeat.intervalType === RepeatIntervalType.MONTH ? (
                <Form.Group className="mt-2 mb-0" controlId="formBasicCheckbox">
                  <Form.Check
                    type="checkbox"
                    label="Last day of month"
                    checked={task.repeat.lastDayOfMonth}
                    onChange={(e) =>
                      doUpdateTask({
                        ...task.repeat,
                        lastDayOfMonth: e.target.checked,
                      })
                    }
                  />
                </Form.Group>
              ) : null}
            </div>
          </div>
          <div className="d-flex align-items-center mt-2">
            <div className="mr-3 mt-1">Ends</div>
            <InputGroup size="sm ml-3">
              <InputGroup.Prepend>
                <Form.Control
                  as="select"
                  size="sm"
                  value={task.repeat.endType ?? RepeatEndType.NEVER}
                  onChange={(e) => onEndTypeChanged(e.target.value)}
                >
                  <option value={RepeatEndType.NEVER}>Never</option>
                  <option value={RepeatEndType.ON_DATE}>On date</option>
                  <option value={RepeatEndType.AFTER_OCCURENCES}>
                    After repeats
                  </option>
                </Form.Control>
              </InputGroup.Prepend>
              {task.repeat.endType === RepeatEndType.AFTER_OCCURENCES ? (
                <FormControl
                  value={endAfterValue}
                  placeholder="1"
                  type="number"
                  isInvalid={!isEndAfterValid}
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value);
                    setEndAfterValue(newValue);
                    if (newValue > 0 && newValue <= 999) {
                      setEndAfterValid(true);
                      doUpdateTask({ ...task.repeat, endAfter: newValue });
                    } else if (isEndAfterValid) {
                      doUpdateTask({ ...task.repeat, endAfter: 1 });
                      setEndAfterValid(false);
                    }
                  }}
                />
              ) : task.repeat.endType == RepeatEndType.ON_DATE ? (
                <DropdownButton
                  className="flex-fill"
                  as={ButtonGroup}
                  id="dropdown-date-picker-repeat"
                  title={formatDate(endOn)}
                  drop="up"
                  size="sm"
                >
                  <DatePicker
                    date={endOn}
                    showButtons={false}
                    onChange={(date) => {
                      doUpdateTask({
                        ...task.repeat,
                        endOn: formatDateDb(date),
                      });
                    }}
                  />
                </DropdownButton>
              ) : null}
            </InputGroup>
          </div>
          <div className="Secondary mt-2 pb-2">
            {repeatPreview(task, task.repeat)}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default TaskRepeat;
