import React from "react";

export default () => (
  // <div style={{ display: "flex", justifyContent: "center", margin: ".5rem" }}>
  //   <div className="lds-dual-ring" />
  // </div>
  <div style={{ margin: "auto" }}>
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);
