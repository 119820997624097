import { useDispatch, useSelector } from "react-redux";
import { clearData } from "../redux/dataSlice";
import { hideAlert, showLoggedOut } from "../redux/alertSlice";
import { selectDeviceId, selectToken, logout } from "../redux/authSlice";
import { filterClear } from "../redux/filterSlice";

export const useApiLogout = (isLoggedIn, forced) => {
  const dispatch = useDispatch();

  const deviceId = useSelector(selectDeviceId);
  const token = useSelector(selectToken);

  const headers = new Headers({
    Origin2: "web@" + deviceId,
    "X-Auth-Token": token,
  });

  const doLogout = () => {
    dispatch(logout());
    dispatch(hideAlert());
    dispatch(filterClear());
    dispatch(clearData());
    if (forced) {
      dispatch(showLoggedOut());
    }
  };

  if (!isLoggedIn) {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/logout`, {
      method: "POST",
      mode: "cors",
      headers: headers,
    })
      .then((response) => {
        if (response.ok) {
          doLogout();
        } else {
          throw new Error("alarm!");
        }
      })
      .catch((error) => {
        doLogout();
        // dispatch(showAlert({ text: "Error occured", type: "danger" }));
      });
  }
};
