import React, { useEffect, useState } from "react";
import "./Login.css";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "react-bootstrap";
import { createDefaultData } from "../../redux/dataSlice";
import { Alert } from "../components/Alert";
import ThemeSwitch from "../components/ThemeSwitch";
import {
  saveToken,
  selectDeviceId,
  createDeviceId,
} from "../../redux/authSlice";
import { LoginFooter } from "./LoginFooter";
import { LoginForm } from "./LoginForm";
import { SocialForm } from "./SocialForm";
import { RecoveryForm } from "./RecoveryForm";
import { selectLoggedOut, hideLoggedOut } from "../../redux/alertSlice";

export default function Login() {
  const deviceId = useSelector(selectDeviceId);
  const dispatch = useDispatch();

  const [isRegister, setIsRegister] = useState(false);
  const [isRecover, setIsRecover] = useState(false);

  const isLoggedOut = useSelector(selectLoggedOut);

  useEffect(() => {
    if (!deviceId) {
      dispatch(createDeviceId());
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleLogin = (result) => {
    if (result.isNew) {
      dispatch(createDefaultData());
    }
    dispatch(hideLoggedOut());
    dispatch(saveToken(result.token));
  };

  return (
    <div className="LoginContainer">
      <Toast
        show={isLoggedOut}
        style={{
          position: "absolute",
          top: 16,
          left: 16,
        }}
        onClose={() => dispatch(hideLoggedOut())}
      >
        <Toast.Header>
          <strong className="mr-auto">Ooops</strong>
        </Toast.Header>
        <Toast.Body>You've been logged out</Toast.Body>
      </Toast>
      <div className="d-flex flex-row-reverse">
        <ThemeSwitch className="my-3 mx-4" />
      </div>
      <div className="Login border rounded-sm" style={{ borderColor: "red" }}>
        <img
          className="mb-3 mx-auto d-block"
          height="100"
          alt="Blitz Do"
          src={process.env.PUBLIC_URL + "/logo192.png"}
        />

        {isRecover ? (
          <RecoveryForm deviceId={deviceId} />
        ) : (
          <div>
            <div className="mb-2">
              <LoginForm
                isRegister={isRegister}
                setIsRecover={setIsRecover}
                onLogin={handleLogin}
                deviceId={deviceId}
              />
            </div>
            <Alert />
            <div className="mb-2 mt-3 mx-auto text-center text-muted">
              or continue with
            </div>
            <SocialForm onLogin={handleLogin} deviceId={deviceId} />
          </div>
        )}

        <hr className="mb-3 mt-5" style={{ backgroundColor: "#bbb" }} />
        <LoginFooter
          isRegister={isRegister}
          isRecover={isRecover}
          setIsRegister={setIsRegister}
          setIsRecover={setIsRecover}
        />
      </div>
    </div>
  );
}
