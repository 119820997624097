module.exports = {
  table_tasks: "tasks",
  table_user_filters: "filters",
  table_projects: "projects",
  table_contexts: "contexts",
  table_tags: "tags",

  free_user_filters: 1,
  free_projects: 3,
  free_contexts: 3,
  free_tags: 10,
  free_reminders: 1,

  date_format: "YYYY-MM-DD HH:mm:ss",

  max_subtasks: 100,
  tasks_portion_size: 50,

  repeat_next_preview: 6,

  sync_delay: 1000,
  refresh_list_when_typing_delay: 500,
  refresh_subtask_when_typing_delay: 500,

  id_adding_project: -101,
  id_adding_context: -102,
  id_adding_tag: -103,
  id_adding_user_filters: -104,

  weekdays: "1234567",

  rank_start: "aaaa",
  rank_end: "zzzz",
  alphabet_size: 26,

  link_google_play:
    "https://play.google.com/store/apps/details?id=com.qwertywayapps.tasks&referrer=utm_source%3Dapp.blitz.do%26utm_medium%3Ddialog",
  link_app_store: "https://apps.apple.com/app/id1463093832",

  default_colors: [
    "#F44336",
    "#E91E63",
    "#9C27B0",
    "#673AB7",
    "#3F51B5",
    "#2196F3",
    "#03A9F4",
    "#00BCD4",
    "#009688",
    "#4CAF50",
    "#8BC34A",
    "#CDDC39",
    "#FFC107",
    "#FF9800",
    "#FF5722",
    "#795548",
    "#607D8B",
    "#20242B",
    "#ffffff",
  ],

  test_users: [
    "qwertyway@gmail.com",
    "artem@whisperarts.com",
    "artem2@whisperarts.com",
    "nykmen@gmail.com",
    "nykmen@mail.ru",
  ],
};
