import React from "react";
import { PlaylistAddCheckRounded as TasksIcon } from "@material-ui/icons";

import DetailsNavbar from "./details/DetailsNavbar";
import DetailsFooter from "./details/DetailsFooter";

const MultipleTasksEditor = ({ tasks }) => {
  return (
    <div className="task-details d-flex flex-column h-100 w-100">
      <DetailsNavbar tasks={tasks} />
      <div className="mx-auto my-auto d-flex flex-column align-items-center">
        <TasksIcon className="mb-1" style={{ fontSize: 64 }} />
        {tasks.length} tasks selected
      </div>
      <DetailsFooter tasks={tasks} />
    </div>
  );
};

export default MultipleTasksEditor;
