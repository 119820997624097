import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import "../utils/extensions";

const storage = window.localStorage;

const state = {
  deviceId: storage.getItem("device_id"),
  token: storage.getItem("token"),
  profile: storage.getObject("profile"),
};

export const authSlice = createSlice({
  name: "auth",
  initialState: state,
  reducers: {
    createDeviceId: (state, action) => {
      let deviceId = uuidv4();
      storage.setItem("device_id", deviceId);
      state.deviceId = deviceId;
    },
    saveProfile: (state, action) => {
      storage.setObject("profile", action.payload);
      state.profile = action.payload;
    },
    saveToken: (state, action) => {
      storage.setItem("token", action.payload);
      state.token = action.payload;
    },
    logout: (state, action) => {
      storage.removeItem("token");
      storage.removeItem("profile");
      storage.removeItem("last_sync");
      storage.removeItem("selected_task");
      storage.removeItem("active_filter");
      storage.removeItem("app_state_nav_open");
      state.token = undefined;
    },
  },
});

export const selectDeviceId = (state) => state.auth.deviceId;
export const selectToken = (state) => state.auth.token;
export const selectProfile = (state) => state.auth.profile;

export const {
  createDeviceId,
  saveToken,
  saveProfile,
  logout,
} = authSlice.actions;

export default authSlice.reducer;
