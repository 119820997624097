import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import TaskItem from "./items/TaskItem";
import DateRangeItem from "./items/DateRangeItem";
import { CSSTransition } from "react-transition-group";
import {
  selectTask,
  selectSelectedTasks,
  selectTasksSynced,
  addTasksToSelection,
  removeTasksFromSelection,
} from "../../redux/dataSlice";

import Loader from "./Loader";
import { selectFilter, filterSetDateRange } from "../../redux/filterSlice";
import { useIndexedDB } from "react-indexed-db";
import Constants from "../../utils/constants";
import { ListGroup } from "react-bootstrap";
import { findByCloudId } from "../../utils/utils";
import { getDateRange } from "../../utils/dateUtils";
import DateRange from "../../utils/dateRange";

const TasksList = () => {
  const selectedTasks = useSelector(selectSelectedTasks);

  const synced = useSelector(selectTasksSynced);

  const filter = useSelector(selectFilter);

  const [tasks, setTasks] = useState([]);

  const tasksDB = useIndexedDB(Constants.table_tasks);
  const dispatch = useDispatch();

  const [isLoadingTasks, setIsLoadingTasks] = useState(true);
  const [hasMoreTasks, setHasMoreTasks] = useState(true);

  const [page, setPage] = useState(0);

  const listRef = useRef(null);
  const canSelectNextOnKey = useRef(true);

  const [initialShiftSelection, setInitialShiftSelection] = useState(
    selectedTasks.length === 0 ? null : selectedTasks[selectedTasks.length - 1]
  );

  useEffect(() => {
    if (page === -1) {
      console.log("--- useEffect: page === -1");
      setIsLoadingTasks(true);
      setPage(0);
      return;
    }
    tasksDB.getAll().then((result) => {
      console.log("--- useEffect: getAll() " + result?.length);

      const totalTasks = result.filter((item) => {
        if (item.deleted || item.archived) {
          return false;
        }
        if (filter.userFilter) {
          return filterByUserFilter(filter.userFilter, item);
        } else {
          return filterByFilter(filter, item);
        }
      });
      const oldTasks = page === 0 ? [] : tasks;
      const newPortion = totalTasks
        .sort((a, b) => {
          if (a.completed && b.completed) {
            return a.dateCompleted < b.dateCompleted ? 1 : -1;
          } else if (a.completed) {
            return 1;
          } else if (b.completed) {
            return -1;
          }

          const byDueOrUpdatedDate = (a, b) => {
            if (a.dateDue && b.dateDue) {
              return a.dateDue < b.dateDue ? -1 : 1;
            } else if (a.dateDue) {
              return -1;
            } else if (b.dateDue) {
              return 1;
            }
            return a.dateUpdated < b.dateUpdated ? 1 : -1;
          };

          if (a.starred && b.starred) {
            return byDueOrUpdatedDate(a, b);
          } else if (a.starred) {
            return -1;
          } else if (b.starred) {
            return 1;
          }

          return byDueOrUpdatedDate(a, b);
        })
        .slice(
          page * Constants.tasks_portion_size,
          (page + 1) * Constants.tasks_portion_size
        );

      if (filter.dateRange) {
        setTasks(oldTasks.concat(newPortion));
      } else {
        var newDateRange;
        var previousDateRange = oldTasks.length
          ? getDateRange(oldTasks[oldTasks.length - 1])
          : null;

        for (var i = 0; i < newPortion.length; i++) {
          newDateRange = getDateRange(newPortion[i]);
          if (!previousDateRange || newDateRange !== previousDateRange) {
            oldTasks.push(newDateRange);
            previousDateRange = newDateRange;
          }
          oldTasks.push(newPortion[i]);
        }
        setTasks(oldTasks);
      }

      setIsLoadingTasks(false);
      setHasMoreTasks(
        totalTasks.length > (page + 1) * Constants.tasks_portion_size
      );
    });
  }, [page]);

  useEffect(() => {
    console.log("--- useEffect: synced");
    setPage(-1);
  }, [filter, synced]);

  const handleScroll = (e) => {
    if (hasMoreTasks && !isLoadingTasks) {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      if (bottom) {
        setIsLoadingTasks(true);
        setPage(page + 1);
        // dispatch(setIsLoadingTasks(true));
      }
    }
  };

  const selectTaskIfCan = (task) => {
    if (task) {
      dispatch(selectTask(task));

      if (listRef.current) {
        //scroll to the view of the selected tasks
        const taskView = [...listRef.current.children].find(
          (item) => item.getAttribute("taskid") == task.cloudId
        );
        if (taskView) {
          taskView.scrollIntoViewIfNeeded(false);
        }
      }
    }
  };

  const handleKeyDown = (e) => {
    if (!canSelectNextOnKey.current || e.ctrlKey || e.shiftKey) {
      return;
    }
    canSelectNextOnKey.current = false;
    setTimeout(() => {
      canSelectNextOnKey.current = true;
    }, 50);

    if (selectedTasks.length != 0) {
      const index = tasks.findIndex(
        (item) =>
          item.cloudId === selectedTasks[selectedTasks.length - 1].cloudId
      );
      if (e.key == "ArrowDown" && index < tasks.length - 1) {
        //do not forget to skip headers
        const newSelection = tasks[index + 1].cloudId
          ? tasks[index + 1]
          : tasks[index + 2];
        selectTaskIfCan(newSelection);
      } else if (e.key == "ArrowUp" && index > 0) {
        const newSelection = tasks[index - 1].cloudId
          ? tasks[index - 1]
          : tasks[index - 2];
        selectTaskIfCan(newSelection);
      }
    } else if (
      (e.key == "ArrowDown" || e.key == "ArrowUp") &&
      tasks.length > 0
    ) {
      const newSelection = tasks[0].cloudId ? tasks[0] : tasks[1];
      selectTaskIfCan(newSelection);
    }
  };

  return (
    // <TransitionGroup
    //   component="ListGroup"
    //   className="list-group TasksList"
    //   onScroll={handleScroll}
    // >

    tasks.length == 0 && !hasMoreTasks ? (
      <div className="d-flex vh-100 justify-content-center align-items-center">
        <img
          className="Header-logo"
          alt="Blitz Do"
          style={{ opacity: 0.4, width: 128 }}
          src={process.env.PUBLIC_URL + "/logo_light_shadow.png"}
        />
      </div>
    ) : (
      <ListGroup
        className="overflow-auto outline-none"
        onScroll={handleScroll}
        activeKey={null}
        tabIndex="0"
        ref={listRef}
        onKeyDown={handleKeyDown}
      >
        {tasks.map((item, index) => {
          const isSelected = findByCloudId(selectedTasks, item.cloudId);
          return item.cloudId ? (
            <div key={item.cloudId} taskid={item.cloudId}>
              <CSSTransition className="task" timeout={500}>
                <TaskItem
                  task={item}
                  isSelected={isSelected}
                  onSelected={(event, task) => {
                    if (event.shiftKey) {
                      _selectWithShift(task);
                    } else if (event.ctrlKey) {
                      _selectWithCtrl(task);
                    } else {
                      _selectWithoutKey(task);
                    }
                  }}
                />
              </CSSTransition>
            </div>
          ) : (
            <div
              className={`ml-5 mr-3 ${index === 0 ? "mt-1" : "mt-3"} mb-1`}
              key={`dateRange_${item}`}
            >
              <DateRangeItem
                dateRange={item}
                onDateRangeSelected={(dateRange) =>
                  dispatch(filterSetDateRange(dateRange))
                }
              />
            </div>
          );
        })}
        {hasMoreTasks ? (
          <div className="my-4 mx-auto" key={"tasks_list_loader"}>
            <Loader />
          </div>
        ) : null}
        {/* /* </TransitionGroup> */}
      </ListGroup>
    )
  );

  function _selectWithoutKey(task) {
    if (
      selectedTasks.length < 2 &&
      findByCloudId(selectedTasks, task.cloudId)
    ) {
      dispatch(selectTask(null));
      setInitialShiftSelection(null);
    } else {
      dispatch(selectTask(task));
      setInitialShiftSelection(task);
    }
  }

  function _selectWithShift(task) {
    if (!initialShiftSelection) {
      setInitialShiftSelection(
        selectedTasks.length === 0
          ? null
          : selectedTasks[selectedTasks.length - 1]
      );
    }
    var lastIndex = initialShiftSelection
      ? tasks.findIndex(
          (item) => item.cloudId === initialShiftSelection.cloudId
        )
      : 0;
    var selectedIndex = tasks.findIndex(
      (item) => item.cloudId === task.cloudId
    );
    dispatch(
      selectTask(
        tasks
          .slice(
            Math.min(lastIndex, selectedIndex),
            Math.max(lastIndex, selectedIndex) + 1
          )
          .filter((item) => item.cloudId)
      )
    );
  }

  function _selectWithCtrl(task) {
    setInitialShiftSelection(task);
    if (findByCloudId(selectedTasks, task.cloudId)) {
      dispatch(removeTasksFromSelection([findByCloudId(tasks, task.cloudId)]));
    } else {
      dispatch(addTasksToSelection([findByCloudId(tasks, task.cloudId)]));
    }
  }
};

function filterByFilter(filter, item) {
  let taskDateRange = getDateRange(item, true);
  if (filter.dateRange) {
    if (filter.dateRange === DateRange.starred) {
      if (!item.starred) {
        return false;
      }
    } else if (filter.dateRange === DateRange.completed) {
      if (!item.completed) {
        return false;
      }
    } else {
      if (filter.dateRange === DateRange.today) {
        //for today we show
        // - due today and overdue, not completed
        // - due today, completed
        // - no date, completed today
        if (taskDateRange === DateRange.today) {
          if (item.completed) {
            let dateDue = moment(item.dateDue);
            if (!moment().isSame(dateDue, "date")) {
              return false;
            }
          }
        } else if (taskDateRange === DateRange.noDate) {
          if (!item.completed) {
            return false;
          }
          let dateCompleted = moment(item.dateCompleted);
          if (!moment().isSame(dateCompleted, "date")) {
            return false;
          }
        } else {
          return false;
        }
      } else if (filter.dateRange !== taskDateRange) {
        return false;
      }
    }
  }

  if (
    item.completed &&
    !filter.project &&
    filter.dateRange !== DateRange.completed &&
    !(
      filter.dateRange === DateRange.today &&
      (taskDateRange === DateRange.today || taskDateRange === DateRange.noDate)
    )
  ) {
    return false;
  }

  if (filter.inbox) {
    if (
      !item.projectCloudId &&
      !item.contextCloudId &&
      (item.tags?.length ?? 0) === 0
    ) {
      return true;
    } else {
      return false;
    }
  }
  if (filter.project) {
    if (filter.project.cloudId !== item.projectCloudId) {
      return false;
    }
  }
  if (filter.context && filter.context.cloudId !== item.contextCloudId) {
    return false;
  }
  if (filter.tags.length) {
    if (item.tags?.length) {
      return filter.tags.every((filterTag) =>
        item.tags.some((tagId) => filterTag.cloudId === tagId)
      );
    } else {
      return false;
    }
  }
  return true;
}

const checkIds = (asString, isNot, type, noCondition, condition) => {
  if (asString) {
    var conditions = [];
    for (var id of asString.split(",")) {
      var result = true;
      if (id === "no_id") {
        if (!noCondition()) {
          result = false;
        }
      } else if (!condition(id)) {
        result = false;
      }
      conditions.push(result);
    }
    const res =
      type === "AND"
        ? conditions.every((it) => it)
        : conditions.some((it) => it);

    return isNot ? !res : res;
  }
  return true;
};

function filterByUserFilter(filter, task) {
  if (task.completed && !filter.includeCompleted) {
    return false;
  }

  if (filter.days === -1) {
    if (task.dateDue) {
      return false;
    }
  } else if (filter.days !== null && filter.days !== undefined) {
    if (!task.dateDue) {
      return false;
    }
    if (
      !moment(task.dateDue).isBefore(
        moment()
          .startOf("day")
          .add(filter.days + 1, "d")
      )
    ) {
      return false;
    }
  }

  var conditions = [];

  if (filter.projects) {
    conditions.push(
      checkIds(
        filter.projects,
        filter.projectsNot,
        "OR",
        () => !task.projectCloudId,
        (id) => task.projectCloudId === id
      )
    );
  }
  if (filter.contexts) {
    conditions.push(
      checkIds(
        filter.contexts,
        filter.contextsNot,
        "OR",
        () => !task.contextCloudId,
        (id) => task.contextCloudId === id
      )
    );
  }
  if (filter.tags) {
    conditions.push(
      checkIds(
        filter.tags,
        filter.tagsNot,
        filter.tagsType,
        () => !task.tags?.length,
        (id) => task.tags?.includes(id)
      )
    );
  }

  return filter.filterType === "AND"
    ? conditions.every((it) => it)
    : conditions.some((it) => it);
}

export default TasksList;
