import React from "react";
import { useDispatch } from "react-redux";
import { FlashOnRounded as UserFilterIcon } from "@material-ui/icons";

const UserFilterItem = (props) => {
  const { userFilter, dispatchFunction, ...rest } = props;
  const dispatch = useDispatch();

  const onClick = (event) => {
    if (dispatchFunction) {
      event.stopPropagation();
      dispatch(dispatchFunction(userFilter));
    }
  };

  return userFilter ? (
    <span
      {...rest}
      style={{ color: userFilter.color }}
      className={`RightMargin Pointer noselect nowrap ${rest.className ?? ""}`}
      onClick={onClick}
    >
      <UserFilterIcon style={{ marginRight: "5px", fontSize: 18 }} />
      {userFilter.name}
    </span>
  ) : null;
};

UserFilterItem.propTypes = {
  // project: PropTypes.object.isRequired,
};

export default UserFilterItem;
