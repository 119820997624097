import { start } from "@popperjs/core";
import moment from "moment";
import Constants from "./constants.js";

export const RepeatEndType = {
  NEVER: "NEVER",
  ON_DATE: "ON_DATE",
  AFTER_OCCURENCES: "AFTER_OCCURENCES",
};

export const RepeatIntervalType = {
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
  YEAR: "YEAR",
};

export const getNextOccurrenceAfter = (repeat, date) => {
  if (
    !date ||
    repeat.interval <= 0 ||
    (repeat.endType === RepeatEndType.AFTER_OCCURENCES && repeat.endAfter <= 1)
  ) {
    return null;
  }

  const dateMoment = moment(date).startOf("day");
  var start = dateMoment.clone();
  const now = moment().startOf("day");

  var type;
  if (repeat.intervalType === RepeatIntervalType.DAY) {
    type = "d";
  } else if (repeat.intervalType === RepeatIntervalType.WEEK) {
    if (
      !repeat.weekDays ||
      !repeat.weekDays
        .split("")
        .some((item) => Constants.weekdays.includes(item))
    ) {
      return null;
    }
    return calculateWeek(start, now, repeat);
  } else if (repeat.intervalType === RepeatIntervalType.MONTH) {
    if (repeat.lastDayOfMonth) {
      start.endOf("month").startOf("day");
      if (!now.isAfter(start) && start.isAfter(dateMoment))
        return checkEnd(start, repeat);
    }
    type = "M";
  } else if (repeat.intervalType === RepeatIntervalType.YEAR) {
    type = "y";
  } else {
    type = "d";
  }

  do {
    const interval = repeat.interval > 0 ? repeat.interval : 1;
    start.add(interval, type);
    if (repeat.intervalType === RepeatIntervalType.MONTH) {
      if (repeat.lastDayOfMonth) {
        start.endOf("month");
      } else {
        //because of 30/31 difference, and also 28 days in february
        var counter = 2;
        while (start.date() !== dateMoment.date()) {
          start = dateMoment.clone().add(interval * counter, type);
          counter++;
        }
      }
    }
  } while (start.isBefore(now));

  return checkEnd(start, repeat);
};

function calculateWeek(start, now, repeat) {
  const isTheDay = (date) => {
    return repeat.weekDays.includes(adjustMoment(date)) && !now.isAfter(date);
  };

  const firstDayOfWeek = adjustMoment(start.clone().startOf("week"));

  do {
    start.add(1, "d");
    while (start.isoWeekday() !== firstDayOfWeek) {
      if (isTheDay(start)) {
        return checkEnd(start, repeat);
      }
      start.add(1, "d");
    }
    start.add(repeat.interval - 1, "w");
  } while (!isTheDay(start));

  return checkEnd(start, repeat);
}

export function adjustMoment(date) {
  return (date.isoWeekday() % 7) + 1;
}

function checkEnd(dateMoment, repeat) {
  if (
    repeat.endType === RepeatEndType.ON_DATE &&
    moment(repeat.endOn).isBefore(dateMoment)
  ) {
    return null;
  } else {
    return dateMoment;
  }
}
