import React from "react";
import { ArrowForwardIosRounded } from "@material-ui/icons";

const ExpandIcon = (props) => {
  const { isExpanded, ...rest } = props;
  return (
    <ArrowForwardIosRounded
      {...rest}
      style={{
        fontSize: 14,
        transform: `rotate(${isExpanded === true ? 90 : 0}deg)`,
      }}
    />
  );
};

export default ExpandIcon;
