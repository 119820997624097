Storage.prototype.setObject = function (key, value) {
  if (value) {
    this.setItem(key, JSON.stringify(value));
  } else {
    this.removeItem(key);
  }
};

Storage.prototype.getObject = function (key) {
  var value = this.getItem(key);
  if (value) {
    try {
      return JSON.parse(value);
    } catch (e) {}
  }
  return null;
};

Array.prototype.random = function () {
  return this[Math.floor(Math.random() * this.length)];
};
