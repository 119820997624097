import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  Form,
} from "react-bootstrap";
import { useInputValue } from "../../hooks/useInputValue";
import { showAlert, hideAlert } from "../../redux/alertSlice";
import { getErrorMessage } from "../../utils/api";

const { detect } = require("detect-browser");

export const LoginForm = ({ isRegister, deviceId, onLogin, setIsRecover }) => {
  const dispatch = useDispatch();

  const emailInput = useInputValue("");
  const passwordInput = useInputValue("");

  useEffect(() => {
    emailInput.setValue("");
    passwordInput.setValue("");
  }, [isRegister]);

  function validateForm() {
    return emailInput.value().length > 0 && passwordInput.value().length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();

    dispatch(hideAlert());

    const browser = detect();

    const info = browser.name + (browser.os ? " on " + browser.os : "");

    const authData = {
      email: emailInput.value(),
      pass: passwordInput.value(),
      info: info,
    };
    const headers = new Headers({
      Origin2: "web@" + deviceId,
    });

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/auth${
        isRegister ? "/reg" : "/login"
      }`,
      {
        method: "POST",
        mode: "cors",
        headers: headers,
        body: JSON.stringify(authData),
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        console.log("Logged in with email", result);
        if (result.error || !result.token) {
          dispatch(
            showAlert({ text: getErrorMessage(result.error), type: "danger" })
          );
        } else {
          onLogin(result);
        }
        // window.localStorage.setItem("token", result.token);
        // setTimeout(() => {
        //   setIsLoaded(true);
        // }, 2000);
      })
      .catch((error) => {
        console.log("Login error", error);
        dispatch(showAlert({ text: getErrorMessage(""), type: "danger" }));
      });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup controlId="email" bssize="large">
        <FormLabel>Email</FormLabel>
        <FormControl autoFocus type="email" {...emailInput.bind} />
      </FormGroup>
      <FormGroup controlId="password" bssize="large">
        <FormLabel>Password</FormLabel>
        <FormControl {...passwordInput.bind} type="password" />
      </FormGroup>
      <Button
        className="mb-1"
        block
        bssize="large"
        disabled={!validateForm()}
        type="error"
      >
        {isRegister ? "Register" : "Login"}
      </Button>
      <div className="small text-muted">
        {isRegister ? (
          <span>
            I agree to the{" "}
            <a
              href="https://blitz.do/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="https://blitz.do/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </span>
        ) : (
          <a
            href={"/recover"}
            onClick={(e) => {
              e.preventDefault();
              setIsRecover(true);
              dispatch(hideAlert());
            }}
          >
            Forgot password?
          </a>
        )}
      </div>
    </Form>
  );
};
