import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Modal, Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import {
  Android,
  Apple,
  Close,
  DesktopWindows,
  DeviceUnknown,
} from "@material-ui/icons";
import { hideDevicesLimitDialog } from "../../../redux/alertSlice";
import { selectDeviceId, selectToken } from "../../../redux/authSlice";
import Constants from "../../../utils/constants";
import { formatDate, formatTime } from "../../../utils/dateUtils";
import { logEvent, openUrl } from "../../../utils/utils";
import { useApiLogout } from "../../../app/api";

const DeviceItem = ({ device, onDelete, onLogout }) => {
  const currentDeviceId = useSelector(selectDeviceId);
  const [isDeleting, setIsDeleting] = useState(false);

  const token = useSelector(selectToken);

  const deviceIcon = (origin) => {
    switch (origin) {
      case "web":
        return <DesktopWindows />;
      case "android":
        return <Android />;
      case "ios":
        return <Apple />;
      default:
        return <DeviceUnknown />;
    }
  };

  const deviceTitle = (device) => {
    const suffix = device.deviceId === currentDeviceId ? " (current)" : "";

    return (
      <div>
        {(device.info?.length == 0 ? device.origin : device.info) + suffix}
      </div>
    );
  };

  const deviceLastSync = (date) => {
    const lastSyncDate = moment(date);
    return (
      <div className="Secondary">
        {formatDate(lastSyncDate)} {formatTime(date)}
      </div>
    );
  };

  const deleteDevice = (deviceId) => {
    setIsDeleting(!isDeleting);

    const headers = new Headers({
      Origin2: "web@" + currentDeviceId,
      "X-Auth-Token": token,
    });

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/unlinkdevice`, {
      method: "POST",
      mode: "cors",
      headers: headers,
      body: JSON.stringify({
        deviceId: deviceId,
      }),
    })
      .then((response) => {
        if (response.ok) {
          if (deviceId === currentDeviceId) {
            onLogout();
          } else {
            onDelete(deviceId);
          }
        } else {
          throw new Error("UnlinkDevice request is not ok");
        }
      })
      .catch((error) => {
        console.log(error);
        setIsDeleting(false);
        onDelete(null);
      });
  };

  return (
    <Container>
      <Row className="px-3 py-2 align-items-center">
        {deviceIcon(device.origin)}
        <div className="px-3">
          {deviceTitle(device)}
          {deviceLastSync(device.lastSync)}
        </div>
        {isDeleting ? (
          <div className="spinner-border ml-auto" role="status" />
        ) : (
          <Close
            className="ml-auto Pointer"
            onClick={() => deleteDevice(device.deviceId)}
          />
        )}
      </Row>
    </Container>
  );
};

const LimitDevicesDialog = ({ data }) => {
  const dispatch = useDispatch();

  const [devices, setDevices] = useState(data.devices);
  const [hasErrorDeleting, setHasErrorDeleting] = useState(false);

  const [isLoggedIn, setLoggedIn] = useState(true);

  useApiLogout(isLoggedIn, true);

  const handleClose = () => {
    dispatch(hideDevicesLimitDialog());
  };

  const removeAlertTimeout = useRef(null);

  useEffect(() => {
    logEvent("limit_devices_shown");
    return () => {
      clearTimeout(removeAlertTimeout.current);
    };
  }, []);

  return (
    <Modal show onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Blitz PRO</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          You can use only {data.limit} active devices in free version. Please,
          remove some devices or upgrade to PRO in the app
        </ul>
        {devices.map((item, index) => (
          <DeviceItem
            device={item}
            key={item.deviceId}
            onDelete={(deviceId) => {
              if (deviceId) {
                if (devices.length - 1 > data.limit) {
                  setDevices(
                    devices.filter((device) => device.deviceId !== deviceId)
                  );
                } else {
                  dispatch(hideDevicesLimitDialog());
                }
              } else {
                clearTimeout(removeAlertTimeout.current);
                setHasErrorDeleting(true);
                removeAlertTimeout.current = setTimeout(
                  () => setHasErrorDeleting(false),
                  2000
                );
              }
            }}
            onLogout={() => {
              setLoggedIn(false);
              dispatch(hideDevicesLimitDialog());
            }}
          />
        ))}
        {hasErrorDeleting ? (
          <Alert className="mt-3" variant="danger">
            Error deleting device. Try again
          </Alert>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row key="buttons">
            <Col>
              <Button
                variant="primary"
                block
                onClick={() => openUrl(Constants.link_app_store)}
              >
                App Store
              </Button>
            </Col>
            <Col>
              <Button
                variant="primary"
                block
                onClick={() => openUrl(Constants.link_google_play)}
              >
                Google Play
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default LimitDevicesDialog;
