import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Dropdown, NavDropdown, Button } from "react-bootstrap";
import { Android, Apple } from "@material-ui/icons";
import { useApiLogout } from "../../app/api";
import { selectProfile } from "../../redux/authSlice";
import ThemeSwitch from "./ThemeSwitch";
import Constants from "../../utils/constants";
import { openUrl } from "../../utils/utils";
import packageJson from "../../../package.json";

export function Header() {
  const [isLoggedIn, setLoggedIn] = useState(true);

  useApiLogout(isLoggedIn);

  const profile = useSelector(selectProfile);

  return (
    <div className="App-header d-flex pl-1 pr-2">
      <img
        className="Header-logo"
        alt="Blitz Do"
        src={process.env.PUBLIC_URL + "/logo192.png"}
      />

      <Dropdown className="ml-auto" alignRight>
        <Dropdown.Toggle
          title=""
          id="basic-nav-dropdown"
          className="app-theme"
        />
        <Dropdown.Menu>
          {profile && profile.email ? (
            <NavDropdown.Item
              disabled
              // onClick={() =>
              //   dispatch(filterSetDateRange(Constants.date_range_completed))
              // }
            >
              {profile.email}
            </NavDropdown.Item>
          ) : null}

          <Dropdown.Divider />
          {/* <Dropdown.Item> */}
          <div className="Primary d-flex dropdown-paddings">
            <span className="mr-auto">Theme</span>
            <ThemeSwitch className="align-middle Pointer" />
          </div>
          {/* <div className="py-2 px-4">
            {["#FFC107", "#2196F3", "#4CAF50", "#607D8B"].map((color) => (
              <Button
                className="btn-circle RightMargin"
                style={{ backgroundColor: color }}
                onClick={() => dispatch(prefsSetAccent(color))}
              />
            ))}
          </div> */}
          {/* </Dropdown.Item> */}
          <Dropdown.Item onClick={() => setLoggedIn(false)}>
            Logout
          </Dropdown.Item>
          <Dropdown.Divider />
          <div className="d-flex Primary dropdown-paddings">
            <span className="mr-auto">Apps</span>
            <Android
              className="Pointer mr-3"
              onClick={() => openUrl(Constants.link_google_play)}
            />
            <Apple
              className="Pointer"
              onClick={() => openUrl(Constants.link_app_store)}
            />
          </div>
          <Dropdown.Divider />
          <div className="Secondary d-flex dropdown-paddings-horizontal">
            <span className="mr-auto">blitz.do</span>
            <span>v.{packageJson.version}</span>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}
