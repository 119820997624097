import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Container, Row, Col } from "react-bootstrap";
import { hidePremium } from "../../../redux/alertSlice";
import Constants from "../../../utils/constants";
import { logEvent, openUrl } from "../../../utils/utils";

const PremiumDialog = ({ message }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hidePremium());
  };

  useEffect(() => {
    logEvent("premium_shown");
  }, []);

  return (
    <Modal show onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Blitz PRO</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          {message}. Upgrade to PRO version in the app to get more freedom:
        </ul>
        <ul>📱&nbsp;&nbsp;Any number of devices with realtime sync</ul>
        <ul>🏷️ Unlimited number of Projects, Contexts and Tags</ul>
        <ul>⏰ Up to 5 reminders for a task</ul>
        <ul>🎨 Any color picker</ul>
        <ul>👍 More features coming soon</ul>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col>
              <Button
                variant="primary"
                block
                onClick={() => openUrl(Constants.link_app_store)}
              >
                App Store
              </Button>
            </Col>
            <Col>
              <Button
                variant="primary"
                block
                onClick={() => openUrl(Constants.link_google_play)}
              >
                Google Play
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default PremiumDialog;
