import React from "react";
import {
  RepeatRounded as Repeat,
  CalendarTodayRounded as DueDate,
} from "@material-ui/icons";

const DueDateIcon = (props) => {
  const { isRepeat, ...rest } = props;

  return isRepeat ? <Repeat {...rest} /> : null;
};

export default DueDateIcon;
