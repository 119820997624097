import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { selectAlert, hideAlert } from "../../redux/alertSlice";

export const Alert = () => {
  const alert = useSelector(selectAlert);
  const dispatch = useDispatch();

  return (
    <CSSTransition
      in={alert.visible}
      timeout={{
        enter: 500,
        exit: 350,
      }}
      classNames={"alert"}
      mountOnEnter
      unmountOnExit
    >
      <div
        className={`alert alert-${alert.type || "warning"} alert-dismissable`}
      >
        &nbsp;{alert.text}
        {alert.dismissable ? (
          <button
            onClick={() => dispatch(hideAlert())}
            type="button"
            className="close"
            aria-label="Close"
          >
            <span aria-label="Close">&times;</span>
          </button>
        ) : null}
      </div>
    </CSSTransition>
  );
};
