import { createSlice } from "@reduxjs/toolkit";

export const alertSlice = createSlice({
  name: "alert",
  initialState: {
    visible: false,
    text: "",
    type: null,
    dismissable: true,
    premiumMessage: null,
    limitDevices: null,
    loggedOut: false,
  },
  reducers: {
    showPremium: (state, action) => {
      state.premiumMessage = action.payload;
    },
    hidePremium: (state, action) => {
      state.premiumMessage = null;
    },
    showDevicesLimitDialog: (state, action) => {
      state.limitDevices = action.payload;
    },
    hideDevicesLimitDialog: (state, action) => {
      state.limitDevices = null;
    },
    showAlert: (state, action) => {
      state.visible = true;
      state.text = action.payload.text;
      state.type = action.payload.type;
      state.dismissable = action.payload.dismissable ?? true;
    },
    hideAlert: (state, action) => {
      state.visible = false;
    },
    showLoggedOut: (state, action) => {
      state.loggedOut = true;
    },
    hideLoggedOut: (state, action) => {
      state.loggedOut = false;
    },
  },
});

export const selectAlert = (state) => state.alert;
export const selectLoggedOut = (state) => state.alert.loggedOut;

export const {
  showAlert,
  hideAlert,
  showPremium,
  hidePremium,
  showDevicesLimitDialog,
  hideDevicesLimitDialog,
  showLoggedOut,
  hideLoggedOut,
} = alertSlice.actions;

export default alertSlice.reducer;
