import { createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { useIndexedDB } from "react-indexed-db";
import { DBConfig } from "../app/db_config";
import { initDB } from "react-indexed-db";
import Constants from "../utils/constants";
import "../utils/extensions";
import moment from "moment";
import {
  findByCloudId,
  removeItemFromArray,
  getExtraCounter,
} from "../utils/utils";
import constants from "../utils/constants";
import { formatDateDb } from "../utils/dateUtils";
import { getNextOccurrenceAfter, RepeatEndType } from "../utils/repeat";

initDB(DBConfig);

const defaultProjects = () => [
  { cloudId: uuidv4(), name: "Welcome", rank: "rrrr" },
];

const defaultContexts = () => [
  { cloudId: uuidv4(), name: "Work", rank: "gmzz" },
  { cloudId: uuidv4(), name: "Home", rank: "mzzy" },
  { cloudId: uuidv4(), name: "FreeTime", rank: "tmzx" },
];

const defaultTags = () => [
  { cloudId: uuidv4(), name: "Urgent", rank: "cxcx", color: "#F44336" },
  { cloudId: uuidv4(), name: "Read", rank: "fufu", color: "#FF9800" },
  { cloudId: uuidv4(), name: "Movies", rank: "irir", color: "#CDDC39" },
  { cloudId: uuidv4(), name: "People", rank: "lolo", color: "#3F51B5" },
  { cloudId: uuidv4(), name: "Sport", rank: "olol", color: "#9C27B0" },
  { cloudId: uuidv4(), name: "Web", rank: "riri", color: "#4CAF50" },
  { cloudId: uuidv4(), name: "Finance", rank: "ufuf", color: "#795548" },
  { cloudId: uuidv4(), name: "Productivity", rank: "xcxc", color: "#607D8B" },
];

const defaultTasks = () => [
  {
    cloudId: uuidv4(),
    name: "Welcome to Blitz",
    dateDue: formatDateDb(moment()),
  },
  {
    cloudId: uuidv4(),
    name: "Tasks can be assigned to projects or contexts, or both",
  },
  {
    cloudId: uuidv4(),
    name: "Tasks can have tags! Even multiple",
  },
  {
    cloudId: uuidv4(),
    name: "Tasks without project, context and tags get into Inbox. Try to keep it clean!",
  },
  {
    cloudId: uuidv4(),
    name: "Keep playing around and stay productive!",
  },
];

const dataState = {
  taskSyncCounter: 0,
  pctSyncCounter: 0,

  selectedTasks: [],

  allUserFilters: [],
  allProjects: [],
  allContexts: [],
  allTags: [],
  toSync: {
    tasks: [],
    filters: [],
    projects: [],
    contexts: [],
    tags: [],
  },
};

const taskDB = useIndexedDB(Constants.table_tasks);
const userFiltersDB = useIndexedDB(Constants.table_user_filters);
const projectDB = useIndexedDB(Constants.table_projects);
const contextDB = useIndexedDB(Constants.table_contexts);
const tagDB = useIndexedDB(Constants.table_tags);

const sortByRankAndDueDate = (a, b) => {
  if (a.rank && b.rank) {
    return a.rank < b.rank ? -1 : 1;
  } else {
    return a.dateUpdated < b.dateUpdated ? 1 : -1;
  }
};

export const dataSlice = createSlice({
  name: "data",
  initialState: dataState,
  reducers: {
    dataLoadedFromDb: (state, action) => {
      switch (action.payload.type) {
        case Constants.table_projects:
          state.allProjects = [...action.payload.data]
            .filter((item) => !item.archived)
            .sort(sortByRankAndDueDate);
          break;
        case Constants.table_contexts:
          state.allContexts = [...action.payload.data].sort(
            sortByRankAndDueDate
          );
          break;
        case Constants.table_tags:
          state.allTags = [...action.payload.data].sort(sortByRankAndDueDate);
          break;
        case Constants.table_user_filters:
          state.allUserFilters = [...action.payload.data].sort(
            sortByRankAndDueDate
          );
          break;
        default:
          break;
      }
    },

    dataGetFromServer: (state, action) => {
      console.log("--- saving filters: " + action.payload.filters?.length);
      updateItemsInDB(action.payload.tasks, taskDB, state.toSync.tasks);
      //update selected task if it's in modified objects
      if (action.payload.tasks) {
        for (let index = 0; index < state.selectedTasks.length; index++) {
          const taskInSelection = state.selectedTasks[index];
          var taskInModified = findByCloudId(
            action.payload.tasks,
            taskInSelection.cloudId
          );
          if (taskInModified) {
            taskInModified =
              taskInModified.deleted || taskInModified.archived
                ? null
                : {
                    ...taskInModified,
                    extra: getExtraCounter(),
                  };
            if (state.selectedTasks.length === 1) {
              setSelected(state, taskInModified);
            } else {
              state.selectedTasks[index] = taskInModified;
            }
          }
        }
        state.selectedTasks = state.selectedTasks.filter(
          (item) => item !== null
        );
      }

      updateItemsInDB(
        action.payload.projects,
        projectDB,
        state.toSync.projects
      );
      updateItemsInDB(
        action.payload.contexts,
        contextDB,
        state.toSync.contexts
      );
      updateItemsInDB(action.payload.tags, tagDB, state.toSync.tags);
      updateItemsInDB(
        action.payload.filters,
        userFiltersDB,
        state.toSync.filters
      );

      if (action.payload.tasks) {
        state.taskSyncCounter++;
      }
      if (
        action.payload.projects ||
        action.payload.contexts ||
        action.payload.tags ||
        action.payload.filters
      ) {
        state.pctSyncCounter++;
      }
    },

    refreshTaskList: (state, action) => {
      state.taskSyncCounter++;
    },

    clearData: (state, action) => {
      state.selectedTasks = [];
      taskDB.clear();
      state.toSync.tasks = [];
      userFiltersDB.clear();
      state.toSync.filters = [];
      projectDB.clear();
      state.toSync.projects = [];
      contextDB.clear();
      state.toSync.contexts = [];
      tagDB.clear();
      state.toSync.tags = [];
    },

    completeTask: (state, action) => {
      var task = action.payload;
      if (task.completed) {
        task.completed = false;
        task.dateCompleted = null;
      } else {
        if (task.repeat) {
          var nextOccurrence = getNextOccurrenceAfter(
            task.repeat,
            task.dateDue
          );
          if (nextOccurrence) {
            const newRepeat = {
              ...task.repeat,
            };
            if (newRepeat.endType === RepeatEndType.AFTER_OCCURENCES) {
              newRepeat.endAfter = newRepeat.endAfter - 1;
              newRepeat.originalDueDate = formatDateDb(nextOccurrence);
            }

            var newTask = {
              ...task,
              cloudId: null,
              completed: false,
              dateCompleted: null,
              dateDue: formatDateDb(nextOccurrence),
              repeat: newRepeat,
              subtasks: task.subtasks
                ? task.subtasks.map((subtask) => ({
                    ...subtask,
                    completed: false,
                  }))
                : null,
              reminders: updateRemindersIfNeeded(task, nextOccurrence),
            };
            taskUpdated(state, newTask);
          }

          task.repeat = null;
        }
        task.completed = true;
        task.dateCompleted = formatDateDb(moment());
      }
      taskUpdated(state, task);
    },

    updateTask: (state, action) => {
      taskUpdated(state, action.payload);
    },
    updateTasks: (state, action) => {
      var tasks = action.payload;
      tasks.forEach((task) => taskUpdated(state, task));
    },

    selectTask: (state, action) => {
      setSelected(state, action.payload);
    },
    addTasksToSelection: (state, action) => {
      setSelected(state, [...state.selectedTasks, ...action.payload]);
    },
    removeTasksFromSelection: (state, action) => {
      setSelected(
        state,
        state.selectedTasks.filter(
          (element) => !findByCloudId(action.payload, element.cloudId)
        )
      );
    },
    updateUserFilter: (state, action) => {
      var userFilter = action.payload;
      if (!userFilter.cloudId) {
        let now = moment().format(Constants.date_format);
        userFilter.cloudId = uuidv4();
        userFilter.dateCreated = now;
        userFilter.dateUpdated = now;
      }
      userFilterUpdated(state, userFilter);
    },
    updateProject: (state, action) => {
      var project = action.payload;
      if (!project.cloudId) {
        let now = moment().format(Constants.date_format);
        project.cloudId = uuidv4();
        project.dateCreated = now;
        project.dateUpdated = now;
      }
      projectUpdated(state, project);
    },

    updateContext: (state, action) => {
      var context = action.payload;
      if (!context.cloudId) {
        let now = moment().format(Constants.date_format);
        context.cloudId = uuidv4();
        context.dateCreated = now;
        context.dateUpdated = now;
      }
      contextUpdated(state, context);
    },

    updateTag: (state, action) => {
      var tag = action.payload;
      if (!tag.cloudId) {
        let now = moment().format(Constants.date_format);
        tag.cloudId = uuidv4();
        tag.dateCreated = now;
        tag.dateUpdated = now;
      }
      if (tag.color === "") {
        tag.color = constants.default_colors.random();
      }
      tagUpdated(state, tag);
    },

    createDefaultData: (state, action) => {
      let now = moment().format(Constants.date_format);

      const updateDates = (items) => {
        items.forEach((item) => {
          item.dateCreated = now;
          item.dateUpdated = now;
        });
        return items;
      };

      var tags = updateDates(defaultTags());
      var contexts = updateDates(defaultContexts());
      var projects = updateDates(defaultProjects());
      var tasks = updateDates(defaultTasks());

      tasks[0].projectCloudId = projects[0].cloudId;
      tasks[1].projectCloudId = projects[0].cloudId;
      tasks[1].contextCloudId = contexts[0].cloudId;
      tasks[2].projectCloudId = projects[0].cloudId;
      tasks[2].tags = [tags[5].cloudId, tags[7].cloudId];
      tasks[4].projectCloudId = projects[0].cloudId;

      state.allProjects = projects;
      state.allContexts = contexts;
      state.allTags = tags;

      updateItemsInDB(projects, projectDB, []);
      updateItemsInDB(contexts, contextDB, []);
      updateItemsInDB(tags, tagDB, []);
      updateItemsInDB(tasks, taskDB, []);

      state.toSync.projects = projects;
      state.toSync.contexts = contexts;
      state.toSync.tags = tags;
      state.toSync.tasks = tasks;
    },
  },
});

function userFilterUpdated(state, userFilter) {
  let now = moment().format(Constants.date_format);
  userFilter = { ...userFilter, dateUpdated: now };

  updateItemsInDB([userFilter], userFiltersDB, []);
  state.allUserFilters = addOrReplace(state.allUserFilters, userFilter, true);
  state.toSync.filters = addOrReplace(state.toSync.filters, userFilter, false);
}

function projectUpdated(state, project) {
  let now = moment().format(Constants.date_format);
  project = { ...project, dateUpdated: now };

  updateItemsInDB([project], projectDB, []);
  state.allProjects = addOrReplace(state.allProjects, project, true);
  state.toSync.projects = addOrReplace(state.toSync.projects, project, false);
}

function contextUpdated(state, context) {
  let now = moment().format(Constants.date_format);
  context = { ...context, dateUpdated: now };

  updateItemsInDB([context], contextDB, []);
  state.allContexts = addOrReplace(state.allContexts, context, true);
  state.toSync.contexts = addOrReplace(state.toSync.contexts, context, false);
}

function tagUpdated(state, tag) {
  let now = moment().format(Constants.date_format);
  tag = { ...tag, dateUpdated: now };

  updateItemsInDB([tag], tagDB, []);
  state.allTags = addOrReplace(state.allTags, tag, true);
  state.toSync.tags = addOrReplace(state.toSync.tags, tag, false);
}

function taskUpdated(state, task) {
  let now = moment().format(Constants.date_format);
  if (!task.cloudId) {
    task.cloudId = uuidv4();
    task.dateCreated = now;
    state.selectedTasks = [task];
    task.isNew = true; //for editor to autofocus
  }
  task = {
    ...task,
    dateUpdated: now,
    subtasks: task.subtasks?.length
      ? task.subtasks.map((subtask, index) => {
          return { ...subtask, position: index + 1 };
        })
      : null,
  };

  for (let index = 0; index < state.selectedTasks.length; index++) {
    const taskInSelection = state.selectedTasks[index];
    if (
      taskInSelection.cloudId === task.cloudId &&
      taskInSelection.dateUpdated < task.dateUpdated
    ) {
      state.selectedTasks[index] = task;
      break;
    }
  }

  state.taskSyncCounter++;
  updateItemsInDB([task], taskDB, []);
  //sync
  state.toSync.tasks = addOrReplace(state.toSync.tasks, task, false);
}

function setSelected(state, task) {
  if (!task) {
    if (state.selectedTasks.length === 0) {
      console.log("skipping select, already null");
      return;
    }
    state.selectedTasks = [];
  } else if (Array.isArray(task)) {
    console.log("updating array ", task.length);
    state.selectedTasks = [...task];
    return;
  } else {
    const selected = state.selectedTasks;
    if (selected.length === 1) {
      console.log(
        "--- selected: ",
        selected[0].dateUpdated,
        " task: ",
        task.dateUpdated
      );
    }
    if (
      selected.length === 1 &&
      selected[0].cloudId === task.cloudId &&
      selected[0].dateUpdated >= task.dateUpdated
      // !task.fromServer
    ) {
      console.log("skipping select, 1 task didn't change");
      return;
    }
    state.selectedTasks = [task];
  }
}

export function updateRemindersIfNeeded(task, newDate) {
  const daysDifference = moment(newDate)
    .startOf("day")
    .diff(moment(task.dateDue).startOf("day"), "days");
  return task.reminders
    ? task.reminders.map((reminder) => {
        const newReminder = { ...reminder };
        const newDateTime = moment(reminder.originalDateTime).add(
          daysDifference,
          "d"
        );
        newReminder.dateTime = formatDateDb(newDateTime);
        newReminder.originalDateTime = formatDateDb(newDateTime);
        return newReminder;
      })
    : null;
}

function addOrReplace(items, item, removeDeleted) {
  if (item.deleted && removeDeleted) {
    return removeItemFromArray(
      items,
      item,
      (item1, item2) => item1.cloudId === item2.cloudId
    );
  }

  const index = items.findIndex((i) => i.cloudId === item.cloudId);
  if (index !== -1) {
    items[index] = item;
    return items;
  } else {
    return [item, ...items];
  }
}

/**
 *
 * @param {New objects data} data
 * @param {DB provider} db
 * @param {Pending sync collection, new objects will be removed from it} syncCollection
 */
function updateItemsInDB(data, db, syncCollection) {
  data &&
    data.forEach((item) => {
      if (item.deleted) {
        db.deleteRecord(item.cloudId);
      } else {
        db.update(item);
      }

      removeItemFromArray(
        syncCollection,
        item,
        (item1, item2) => item1.cloudId === item2.cloudId
      );
    });
}

export const {
  dataLoadedFromDb,

  dataGetFromServer,
  clearData,
  refreshTaskList,

  completeTask,
  updateTask,
  updateTasks,
  selectTask,
  addTasksToSelection,
  removeTasksFromSelection,

  updateUserFilter,
  updateProject,
  updateContext,
  updateTag,

  createDefaultData,
} = dataSlice.actions;

export const selectSelectedTasks = (state) => state.data.selectedTasks;
export const selectSelectedTasksCount = (state) =>
  state.data.selectedTasks.length;

export const selectDataToSync = (state) => state.data.toSync;

export const selectTasksSynced = (state) => state.data.taskSyncCounter;
export const selectPctSynced = (state) => state.data.pctSyncCounter;

export const selectAllUserFilters = (state) => state.data.allUserFilters ?? [];
export const selectAllProjects = (state) => state.data.allProjects;
export const selectAllContexts = (state) => state.data.allContexts;
export const selectAllTags = (state) => state.data.allTags;

export default dataSlice.reducer;
