import Constants from "../utils/constants";

export const DBConfig = {
  name: "tasks_db",
  version: 2,
  objectStoresMeta: [
    {
      store: Constants.table_tasks,
      storeConfig: { keyPath: "cloudId", autoIncrement: false },
      storeSchema: [
        { name: "cloudId", keypath: "cloudId", options: { unique: true } },
        // { name: "name", keypath: "name", options: { unique: false } },
      ],
    },
    {
      store: Constants.table_projects,
      storeConfig: { keyPath: "cloudId", autoIncrement: false },
      storeSchema: [
        { name: "cloudId", keypath: "cloudId", options: { unique: true } },
      ],
    },
    {
      store: Constants.table_contexts,
      storeConfig: { keyPath: "cloudId", autoIncrement: false },
      storeSchema: [
        { name: "cloudId", keypath: "cloudId", options: { unique: true } },
      ],
    },
    {
      store: Constants.table_tags,
      storeConfig: { keyPath: "cloudId", autoIncrement: false },
      storeSchema: [
        { name: "cloudId", keypath: "cloudId", options: { unique: true } },
      ],
    },
    {
      store: Constants.table_user_filters,
      storeConfig: { keyPath: "cloudId", autoIncrement: false },
      storeSchema: [
        { name: "cloudId", keypath: "cloudId", options: { unique: true } },
      ],
    },
  ],
};
