import React from "react";
import { SvgIcon } from "@material-ui/core";

export default function ProjectIcon(props) {
  const { ...rest } = props;

  return (
    <SvgIcon {...rest}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19 2h-4.18C14.4.84 13.3 0 12 0S9.6.84 9.18 2H5c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm6 18H6c-.55 0-1-.45-1-1V5c0-.55.45-1 1-1h1v1c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V4h1c.55 0 1 .45 1 1v14c0 .55-.45 1-1 1z" />
    </SvgIcon>
  );
}
