import React from "react";
import TagItem from "./items/TagItem";
import { useDispatch, useSelector } from "react-redux";
import {
  InboxRounded,
  TodayRounded,
  EventBusyRounded as NoDateIcon,
  CheckCircleRounded as CompletedIcon,
} from "@material-ui/icons";
import {
  selectFilter,
  filterSetProject,
  filterSetContext,
  filterRemoveTag,
  filterSetDateRange,
  filterSetInbox,
  filterSetUserFilter,
} from "../../redux/filterSlice";
import {
  selectAllProjects,
  selectAllContexts,
  selectAllTags,
  selectAllUserFilters,
} from "../../redux/dataSlice";
import ContextItem from "./items/ContextItem";
import ProjectItem from "./items/ProjectItem";
import StarIcon from "./icons/StarIcon";
import { findByCloudId } from "../../utils/utils";
import DateRange from "../../utils/dateRange";
import { getDateRangeName } from "../../utils/dateUtils";
import UserFilterItem from "./items/UserFilterItem";

const Filter = () => {
  const dispatch = useDispatch();
  const filter = useSelector(selectFilter);

  const allProjects = useSelector(selectAllProjects);
  const allContexts = useSelector(selectAllContexts);
  const allTags = useSelector(selectAllTags);
  const allUserFilters = useSelector(selectAllUserFilters);

  const isActive = () =>
    filter?.inbox ||
    filter?.userFilter ||
    filter?.project ||
    filter?.context ||
    filter?.dateRange ||
    filter?.tags.length > 0;

  return (
    <div
      className="d-flex"
      style={{
        marginLeft: "10px",
        marginRight: "10px",
        marginBottom: isActive() ? "10px" : "0px",
      }}
    >
      <div>
        {filter.inbox ? (
          <span
            className="RightMargin Pointer noselect"
            onClick={() => dispatch(filterSetInbox(false))}
          >
            <InboxRounded
              className="Icon"
              style={{ marginRight: "5px", fontSize: 18 }}
            />
            Inbox
          </span>
        ) : null}
        {filter.userFilter ? (
          <UserFilterItem
            userFilter={findByCloudId(
              allUserFilters,
              filter.userFilter.cloudId
            )}
            dispatchFunction={filterSetUserFilter}
          />
        ) : null}
        {filter.project ? (
          <ProjectItem
            project={findByCloudId(allProjects, filter.project.cloudId)}
            dispatchFunction={filterSetProject}
          />
        ) : null}
        {filter.context ? (
          <ContextItem
            context={findByCloudId(allContexts, filter.context.cloudId)}
            dispatchFunction={(_) => filterSetContext(null)}
          />
        ) : null}
        {filter.tags.map((value, index) => (
          <TagItem
            key={value.cloudId}
            tag={findByCloudId(allTags, value.cloudId)}
            dispatchFunction={filterRemoveTag}
          />
        ))}
      </div>
      {filter.dateRange && (
        <span
          className="Pointer noselect ml-auto nowrap"
          onClick={() => dispatch(filterSetDateRange(null))}
        >
          {filter.dateRange === DateRange.completed ? (
            <span>
              <CompletedIcon style={{ marginRight: "5px", fontSize: 18 }} />
              {getDateRangeName(filter.dateRange)}
            </span>
          ) : filter.dateRange === DateRange.starred ? (
            <span>
              <StarIcon
                isStarred
                style={{ marginRight: "5px", fontSize: 18 }}
              />
              {getDateRangeName(filter.dateRange)}
            </span>
          ) : filter.dateRange === DateRange.noDate ? (
            <span>
              <NoDateIcon
                style={{
                  marginRight: "5px",
                  fontSize: 18,
                }}
              />
              {getDateRangeName(filter.dateRange)}
            </span>
          ) : (
            <span>
              <TodayRounded style={{ marginRight: "5px", fontSize: 18 }} />
              {getDateRangeName(filter.dateRange)}
            </span>
          )}
        </span>
      )}
    </div>
  );
};

export default Filter;
