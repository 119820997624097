import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIndexedDB } from "react-indexed-db";
import Constants from "./utils/constants";
import {
  dataLoadedFromDb,
  selectPctSynced,
  selectDataToSync,
  selectTask,
  selectSelectedTasksCount,
} from "./redux/dataSlice";
import { selectDeviceId, selectToken } from "./redux/authSlice";
import Details from "./features/components/Details";
import { Header } from "./features/components/Header";
import Nav from "./features/components/Nav";
import NewTaskInput from "./features/components/NewTaskInput";
import TasksList from "./features/components/TasksList";
import Filter from "./features/components/Filter";
import Loader from "./features/components/Loader";
import { Alert } from "./features/components/Alert";
import { useApiLogout } from "./app/api";
import WS from "./app/ws";
import { sendDataToServer } from "./app/ws";
import {
  showAlert,
  hideAlert,
  selectAlert,
  showDevicesLimitDialog,
} from "./redux/alertSlice";
import { dataGetFromServer } from "./redux/dataSlice";
import { saveProfile } from "./redux/authSlice";
import PremiumDialog from "./features/components/dialogs/PremiumDialog";
import LimitDevicesDialog from "./features/components/dialogs/LimitDevicesDialog";

export function Content() {
  const deviceId = useSelector(selectDeviceId);
  const token = useSelector(selectToken);

  const [error, setError] = useState(null);
  const [status, setStatus] = useState(null);

  const [isReconnecting, setReconnecting] = useState(false);

  const [reconnect, setReconnect] = useState(1);

  const [isLoggedIn, setLoggedIn] = useState(true);
  useApiLogout(isLoggedIn, true);

  const dispatch = useDispatch();

  const projectDB = useIndexedDB(Constants.table_projects);
  const contextDB = useIndexedDB(Constants.table_contexts);
  const tagDB = useIndexedDB(Constants.table_tags);
  const filtersDB = useIndexedDB(Constants.table_user_filters);

  const synced = useSelector(selectPctSynced);
  const toSync = useSelector(selectDataToSync);
  const syncTimeout = useRef(null);

  const isConnected = useRef(false);
  const isVisible = useRef(true);
  const webSocket = useRef(null);

  const selectedTasksCount = useSelector(selectSelectedTasksCount);
  const alert = useSelector(selectAlert);

  useEffect(() => {
    projectDB.getAll().then((data) => {
      dispatch(
        dataLoadedFromDb({ type: Constants.table_projects, data: data })
      );
    });
    contextDB.getAll().then((data) => {
      dispatch(
        dataLoadedFromDb({ type: Constants.table_contexts, data: data })
      );
    });
    tagDB.getAll().then((data) => {
      dispatch(dataLoadedFromDb({ type: Constants.table_tags, data: data }));
    });
    filtersDB.getAll().then((data) => {
      dispatch(
        dataLoadedFromDb({ type: Constants.table_user_filters, data: data })
      );
    });
  }, [synced]);

  useEffect(() => {
    clearTimeout(syncTimeout.current);

    if (
      webSocket.current &&
      (toSync.tasks?.length ||
        toSync.projects?.length ||
        toSync.contexts?.length ||
        toSync.tags?.length ||
        toSync.filters?.length)
    ) {
      syncTimeout.current = setTimeout(
        () => sendDataToServer(webSocket.current, toSync),
        Constants.sync_delay
      );
    }
    return () => {
      clearTimeout(syncTimeout.current);
    };
  }, [toSync]);

  const URL = `${process.env.REACT_APP_BASE_URL.replace("https", "wss").replace(
    "http",
    "ws"
  )}/api/v2/sync?Origin2=web@${deviceId}&X-Auth-Token=${token}`;

  const onConnectionStateChanged = (isConnectionOpen) => {
    isConnected.current = isConnectionOpen;
    if (isConnectionOpen) {
      setError(null);
      setReconnecting(false);
      dispatch(hideAlert());
      sendDataToServer(webSocket.current, toSync);
    } else {
      if (!isVisible.current && webSocket.current) {
        webSocket.current.close();
      }
      console.log("--- CONNECTION ERROR. isReconnecting: ", isReconnecting);
      if (isReconnecting && isVisible.current) {
        dispatch(
          showAlert({
            text: "Connection error",
            type: "danger",
            dismissable: false,
          })
        );
      } else {
        setReconnecting(true);
      }
    }
  };

  useEffect(() => {
    if (webSocket.current) {
      webSocket.current.close();
    }

    webSocket.current = WS(
      URL,
      document.hasFocus(),
      (profile) => {
        if (profile.limitDevices) {
          dispatch(showDevicesLimitDialog(profile.limitDevices));
          delete profile["limitDevices"];
        }
        dispatch(saveProfile(profile));
      },
      (isConnectionOpen) => {
        onConnectionStateChanged(isConnectionOpen);
      },
      (data) => {
        dispatch(dataGetFromServer(data));
      },
      () => {
        setLoggedIn(false);
      }
    );
    setStatus("OK");
    // webSocket.current.
    return () => {
      webSocket.current.close();
      webSocket.current = null;
    };
  }, [reconnect]);

  const onFocus = () => {
    isVisible.current = true;
    if (!isConnected.current) {
      setReconnect(reconnect + 1);
    }
  };

  const onBlur = () => {
    isVisible.current = false;
    if (isConnected.current && webSocket.current) {
      // webSocket.current.close();
    }
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  });

  const onKeyUp = (event) => {
    if (event.key === "Escape" && selectSelectedTasksCount !== 0) {
      dispatch(selectTask(null));
    } else if (event.key === "N" && selectedTasksCount === 0) {
      //TODO Create new task. Consider inputs
      // dispatch(selectTask({}));
    } else if (event.key === "Q") {
      //TODO: focus new task input
    }
  };

  if (error) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        {/* Error */}
        {/* {reconnecting ? ( */}
        <Alert key="1" variant="primary">
          Reconnecting in 5 seconds
        </Alert>
        {/* ) : null} */}
      </div>
    );
  } else if (webSocket.current == null || status == null) {
    return (
      <div className="App-Theme d-flex vh-100 justify-content-center">
        <Loader />
      </div>
    );
  } else {
    return (
      // order matters for shadows
      <div className="App outline-none" onKeyUp={onKeyUp} tabIndex="0">
        {alert.premiumMessage ? (
          <PremiumDialog message={alert.premiumMessage} />
        ) : null}
        {alert.limitDevices ? (
          <LimitDevicesDialog data={alert.limitDevices} />
        ) : null}
        <Header />
        <Nav />
        <Details />
        <div className="App-list shadow">
          <Alert />
          <NewTaskInput />
          <Filter />
          <TasksList />
        </div>
      </div>
    );
  }
}
