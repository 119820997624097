import { createSlice } from "@reduxjs/toolkit";
import "../utils/extensions";
import { removeItemFromArray } from "../utils/utils";

const storage = window.localStorage;

const state = storage.getObject("active_filter") ?? {
  inbox: false,
  completed: false,
  userFilter: null,
  context: null,
  project: null,
  dateRange: null,
  tags: [],
};

export const filterSlice = createSlice({
  name: "filter",
  initialState: state,
  reducers: {
    filterClear: (state, action) => {
      clear(state);
      state.dateRange = null;
      save(null);
    },
    filterSetUserFilter: (state, action) => {
      if (!state.userFilter) {
        clear(state);
      }
      state.dateRange = null;
      console.log(state.userFilter);
      if (
        !state.userFilter ||
        state.userFilter.cloudId !== action.payload?.cloudId
      ) {
        state.userFilter = action.payload;
      } else {
        state.userFilter = null;
      }
      save(state);
    },
    filterSetProject: (state, action) => {
      state.inbox = false;
      state.userFilter = null;
      if (!state.project || state.project.cloudId !== action.payload?.cloudId) {
        //reset on second click
        state.project = action.payload;
      } else {
        state.project = null;
      }
      save(state);
    },
    filterSetContext: (state, action) => {
      state.inbox = false;
      state.userFilter = null;
      if (!state.context || state.context.cloudId !== action.payload?.cloudId) {
        //reset on second click
        state.context = action.payload;
      } else {
        state.context = null;
      }
      save(state);
    },
    filterAddTag: (state, action) => {
      state.inbox = false;
      state.userFilter = null;
      const index = state.tags.findIndex(
        (item) => item.cloudId === action.payload.cloudId
      );
      if (index === -1) {
        state.tags = [...state.tags, action.payload];
      } else {
        state.tags.splice(index, 1);
      }
      save(state);
    },
    filterRemoveTag: (state, action) => {
      state.tags = state.tags.filter(
        (item) => item.cloudId !== action.payload.cloudId
      );
      save(state);
    },
    filterSetDateRange: (state, action) => {
      state.userFilter = null;
      if (state.dateRange === action.payload) {
        state.dateRange = null;
      } else {
        state.dateRange = action.payload;
      }
      save(state);
    },
    filterSetInbox: (state, action) => {
      const inboxPreviousValue = state.inbox;
      clear(state);
      state.inbox = !inboxPreviousValue;
      save(state);
    },
    filterNotifyEntityDeleted: (state, action) => {
      if (state.userFilter?.cloudId === action.payload.cloudId) {
        state.userFilter = null;
        save(state);
      } else if (state.project?.cloudId === action.payload.cloudId) {
        state.project = null;
        save(state);
      } else if (state.context?.cloudId === action.payload.cloudId) {
        state.context = null;
        save(state);
      } else {
        state.tags.forEach((item) => {
          if (item.cloudId === action.payload.cloudId) {
            state.tags = removeItemFromArray(
              state.tags,
              item,
              (i1, i2) => i1.cloudId === i2.cloudId
            );
            save(state);
          }
        });
      }
    },
  },
});

function clear(state) {
  state.inbox = false;
  state.completed = false;
  state.userFilter = null;
  state.project = null;
  state.context = null;
  // state.dateRange = null;
  state.tags = [];
}

function save(state) {
  storage.setObject("active_filter", state);
}

// export const selectIsLoadingTasks = (state) => state.filter.isLoadingTasks;
export const selectFilter = (state) => state.filter;

export const {
  filterClear,

  filterSetUserFilter,
  filterSetInbox,
  filterSetProject,
  filterSetContext,
  filterAddTag,
  filterRemoveTag,
  filterSetDateRange,
  filterNotifyEntityDeleted,
} = filterSlice.actions;

export default filterSlice.reducer;
