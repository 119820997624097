import React from "react";
import moment from "moment";
import TextareaAutosize from "react-textarea-autosize";
import { useDispatch, useSelector } from "react-redux";
import { updateTask } from "../../redux/dataSlice";
import { useInputValue } from "../../hooks/useInputValue";
import { selectFilter } from "../../redux/filterSlice";
import { formatDateDb } from "../../utils/dateUtils";
import DateRange from "../../utils/dateRange";

const NewTaskInput = () => {
  const titleInput = useInputValue("");
  const dispatch = useDispatch();

  const filter = useSelector(selectFilter);

  return (
    <form id="new-task-input" className="NewTaskInput">
      <TextareaAutosize
        {...titleInput.bind}
        autoFocus
        type="text"
        placeholder="New task"
        className="w-100 TaskTitle"
        maxLength="500"
        minRows={1}
        maxRows={5}
        style={{ resize: "none" }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            if (titleInput.value().trim()) {
              dispatch(
                updateTask({
                  name: titleInput.value(),
                  fromQuickInput: true,
                  projectCloudId: filter?.project?.cloudId,
                  contextCloudId: filter?.context?.cloudId,
                  tags: filter?.tags?.map((item) => item.cloudId),
                  starred: filter?.dateRange === DateRange.starred,
                  dateDue:
                    filter?.dateRange === DateRange.today
                      ? formatDateDb(moment())
                      : null,
                })
              );
              titleInput.clear();
            }
          }
        }}
      />
    </form>
  );
};

export default NewTaskInput;
