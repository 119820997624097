import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { DayPickerSingleDateController } from "react-dates";
import moment from "moment";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../styles/react_dates_overrides.scss";
import { formatDate } from "../../utils/dateUtils";

export default function DatePicker({ date, onChange, showButtons }) {
  const [focused, setFocused] = useState(false);

  return (
    <div className="my-n2">
      <DayPickerSingleDateController
        onDateChange={(date) => onChange(date)}
        onFocusChange={({ focused }) => setFocused(focused)}
        focused={focused}
        date={date}
        // displayFormat="YYYY-MM-DD"
        hideKeyboardShortcutsPanel
        numberOfMonths={1}
        // withFullScreenPortal={window.innerWidth < 400}
        renderCalendarInfo={() => {
          return showButtons ? (
            <div className="d-flex px-4 pb-3">
              <Button
                variant="outline-primary"
                size="sm"
                onClick={(_) => {
                  onChange(null);
                }}
              >
                Clear
              </Button>
              <Button
                variant="outline-primary"
                className="ml-auto RightMargin"
                size="sm"
                onClick={(_) => {
                  onChange(moment());
                }}
              >
                {formatDate(moment())}
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={(_) => {
                  onChange(moment().add(1, "days"));
                }}
              >
                {formatDate(moment().add(1, "days"))}
              </Button>
            </div>
          ) : null;
        }}
      />
    </div>
  );
}
