import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectTask, selectSelectedTasks } from "../../redux/dataSlice";
import TaskEditor from "./TaskEditor";
import MultipleTasksEditor from "./MultipleTasksEditor";
import ThemedButton from "./details/ThemedButton";

const Details = () => {
  const dispatch = useDispatch();
  const selectedTasks = useSelector(selectSelectedTasks);

  return (
    <div className="App-details">
      {selectedTasks.length === 0 ? (
        <div className="centerContainer">
          <div>
            <div className="centerHorizontal">Nothing selected</div>

            <ThemedButton
              className="centerHorizontal mt-3"
              onClick={() => dispatch(selectTask({}))}
            >
              + New task
            </ThemedButton>
          </div>
        </div>
      ) : selectedTasks.length === 1 ? (
        <TaskEditor key={selectedTasks[0].cloudId} task={selectedTasks[0]} />
      ) : (
        <MultipleTasksEditor tasks={selectedTasks} />
      )}
    </div>
  );
};

export default Details;
