import React from "react";
import { getDateRangeName } from "../../../utils/dateUtils";
import ColoredLine from "./../ColoredLine";

const DateRangeItem = (props) => {
  const { dateRange, onDateRangeSelected, ...rest } = props;

  return (
    <div className="d-flex" {...rest}>
      <div style={{ width: "100px", opacity: 0.4 }}>
        <ColoredLine color={"var(--text-color-secondary)"} />
      </div>
      <span
        className="px-4 Secondary Pointer d-flex flex-column justify-content-center pb-1 noselect"
        onClick={() => onDateRangeSelected(dateRange)}
      >
        <div>{getDateRangeName(dateRange)}</div>
      </span>
      <div className="flex-fill" style={{ opacity: 0.4 }}>
        <ColoredLine color={"var(--text-color-secondary)"} />
      </div>
    </div>
  );
};

export default DateRangeItem;
