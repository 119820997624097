import { createSlice } from "@reduxjs/toolkit";

const storage = window.localStorage;

const state = storage.getObject("app_prefs") ?? {
  theme: "dark",
  accent: "#007bff",
};

export const prefsSlice = createSlice({
  name: "prefs",
  initialState: state,
  reducers: {
    prefsSetAccent: (state, action) => {
      state.accent = action.payload;
      saveState(state);
    },
    prefsSetTheme: (state, action) => {
      const newTheme = action.payload === "dark" ? "dark" : "light";
      state.theme = newTheme;
      saveState(state);
    },
  },
});

function saveState(state) {
  storage.setObject("app_prefs", state);
}

export const selectPrefsThemeIsDark = (state) => state.prefs.theme === "dark";
export const selectPrefsAccent = (state) => state.prefs.accent;

export const { prefsSetTheme, prefsSetAccent } = prefsSlice.actions;

export default prefsSlice.reducer;
