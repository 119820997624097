import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import arrayMove from "array-move";
import TextareaAutosize from "react-textarea-autosize";

import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { updateTask } from "../../redux/dataSlice";
import DetailsNavbar from "./details/DetailsNavbar";
import DetailsFooter from "./details/DetailsFooter";
import SubtaskItem from "./items/SubtaskItem";
import constants from "../../utils/constants";
import { getExtraCounter } from "../../utils/utils";
import ThemedButton from "./details/ThemedButton";

const SortableItem = SortableElement((props) => {
  const value = props.value;

  const key =
    "key:" + value.position + props.task.extra + props.task.subtasks.length;

  return (
    <li>
      <SubtaskItem
        key={key}
        subtask={value}
        task={props.task}
        form={props.form}
        createNewSubtask={props.createNewSubtask}
      />
    </li>
  );
});

const SortableList = SortableContainer((props) => {
  return (
    <ul style={{ paddingInlineStart: "0" }}>
      {[...props.items]
        .sort((a, b) => a.position - b.position)
        .map((value, index) => (
          <SortableItem
            key={`item-${value.position}`}
            index={value.position}
            value={value}
            task={props.task}
            form={props.form}
            createNewSubtask={props.createNewSubtask}
          />
        ))}
    </ul>
  );
});

const TaskEditor = ({ task }) => {
  const dispatch = useDispatch();

  const refreshTimeout = useRef(null);

  const formRef = useRef(null);
  const titleRef = useRef(null);
  const [cursor, setCursor] = useState(null);

  const [name, setName] = useState(task.name);
  const [description, setDescription] = useState(task.description ?? "");

  //for some reason inputs are not updated when new value comes from server
  useEffect(() => {
    setName(task.name);
    setDescription(task.description);

    const input = titleRef.current;
    if (input && name)
      input.setSelectionRange(cursor ?? name.length, cursor ?? name.length);
  }, [task.name, task.description, titleRef]);

  const handleTimer = (name, description) => {
    clearTimeout(refreshTimeout.current);
    refreshTimeout.current = setTimeout(() => {
      dispatch(
        updateTask({
          ...task,
          name: name,
          description: description,
        })
      );
    }, constants.refresh_list_when_typing_delay);
  };

  const createNewSubtask = (fromTop) => {
    if (task.subtasks?.length >= constants.max_subtasks) {
      return null;
    }
    const newSubtask = {
      name: "",
      completed: false,
      isNew: true,
      position:
        task.subtasks?.length > 0 && !fromTop
          ? task.subtasks.reduce((item1, item2) =>
              item1.position > item2.position ? item1 : item2
            ).position + 1
          : 0,
    };
    return newSubtask;
  };

  return (
    <div className="task-details d-flex flex-column h-100 w-100 Scrollable">
      <DetailsNavbar tasks={[task]} />
      <div className="Scrollable mb-auto px-2">
        <TextareaAutosize
          minRows={1}
          maxRows={5}
          ref={titleRef}
          style={{ resize: "none" }}
          value={name ?? ""}
          type="text"
          autoFocus={
            !task.cloudId || (task.isNew && task.name && !task.fromQuickInput)
              ? true
              : false
          }
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          maxLength="500"
          placeholder="Task"
          className="my-2 w-100 TaskTitle"
          onChange={(e) => {
            setCursor(e.target.selectionStart);
            setName(e.target.value);
            handleTimer(e.target.value, description);
          }}
        />

        <TextareaAutosize
          className="my-2 w-100"
          maxLength="8000"
          autoFocus={task.isNew && task.description ? true : false}
          placeholder="Details"
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            handleTimer(name, e.target.value);
          }}
          minRows={5}
        />
        <div className="d-flex justify-content-center my-2">
          {task.subtasks?.length >= constants.max_subtasks ? (
            <div>Max subtasks reached</div>
          ) : (
            <ThemedButton
              size="sm"
              onClick={(_) => {
                const newSubtask = createNewSubtask(true);
                if (newSubtask) {
                  dispatch(
                    updateTask({
                      ...task,
                      subtasks: task.subtasks
                        ? [
                            newSubtask,
                            ...task.subtasks.map((item) => ({
                              ...item,
                              position: item.position + 1,
                              isNew: false,
                            })),
                          ]
                        : [newSubtask],
                    })
                  );
                }
              }}
            >
              +Add subtask
            </ThemedButton>
          )}
        </div>
        <form ref={formRef}>
          <SortableList
            items={task.subtasks ?? []}
            task={task}
            createNewSubtask={createNewSubtask}
            form={formRef}
            helperClass="subtask-dragging"
            useDragHandle
            lockToContainerEdges
            axis="xy"
            lockOffset={["0%", "0%"]}
            onSortEnd={({ oldIndex, newIndex }) => {
              if (oldIndex !== newIndex) {
                dispatch(
                  updateTask({
                    ...task,
                    extra: getExtraCounter(),
                    subtasks: arrayMove(
                      task.subtasks.map((item) => ({ ...item, isNew: false })),
                      oldIndex - 1,
                      newIndex - 1
                    ),
                  })
                );
              }
            }}
          />
        </form>
      </div>
      <DetailsFooter tasks={[task]} />
    </div>
  );
};

export default TaskEditor;
