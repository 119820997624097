import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import appStateReducer from "../redux/appStateSlice";
import dataReducer from "../redux/dataSlice";
import alertReducer from "../redux/alertSlice";
import authReducer from "../redux/authSlice";
import filterReducer from "../redux/filterSlice";
import prefsReducer from "../redux/prefsSlice";

export default configureStore({
  reducer: {
    appState: appStateReducer,
    data: dataReducer,
    alert: alertReducer,
    auth: authReducer,
    filter: filterReducer,
    prefs: prefsReducer,
  },
  middleware: [...getDefaultMiddleware()],
});
