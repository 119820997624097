import React from "react";
import { StarRounded, StarBorderRounded } from "@material-ui/icons";

const StarIcon = (props) => {
  const { isStarred, ...rest } = props;

  return isStarred === true ? (
    <StarRounded {...rest} />
  ) : (
    <StarBorderRounded {...rest} />
  );
};

export default StarIcon;
