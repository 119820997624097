import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropdownButton, ButtonGroup } from "react-bootstrap";
import {
  updateTasks,
  selectAllContexts,
  selectAllProjects,
  selectAllTags,
  updateRemindersIfNeeded,
} from "../../../redux/dataSlice";
import DatePicker from "../DatePicker";
import { formatDate, formatDateDb } from "../../../utils/dateUtils";
import {
  findByCloudId,
  findByCloudIds,
  removeItemFromArray,
} from "../../../utils/utils";
import moment from "moment";
import TagItem from "../items/TagItem";
import SelectableDropdown from "./SelectableDropdown";
import TagsDropdown from "./TagsDropdown";
import TaskRepeat from "./TaskRepeat";

const DetailsFooter = ({ tasks }) => {
  const dispatch = useDispatch();
  const allProjects = useSelector(selectAllProjects);
  const project = useSelector((state) =>
    findByCloudId(
      allProjects,
      tasks.reduce(
        (projectCloudId, item) =>
          item.projectCloudId === projectCloudId ? projectCloudId : null,
        tasks[0].projectCloudId
      )
    )
  );

  const allContexts = useSelector(selectAllContexts);
  const context = useSelector((state) =>
    findByCloudId(
      allContexts,
      tasks.reduce(
        (contextCloudId, item) =>
          item.contextCloudId === contextCloudId ? contextCloudId : null,
        tasks[0].contextCloudId
      )
    )
  );

  const allTags = useSelector(selectAllTags);
  const tags = useSelector((state) =>
    findByCloudIds(
      allTags,
      tasks.reduce(
        (tags, item) =>
          tags && tags.filter((tag) => item.tags && item.tags.includes(tag)),
        tasks[0].tags
      )
    )
  );

  const dateDue = tasks.reduce(
    (dateDue, item) => {
      if (!dateDue || !item.dateDue) {
        return null;
      }
      return dateDue.isSame(moment(item.dateDue), "d") ? dateDue : null;
    },
    tasks[0].dateDue ? moment(tasks[0].dateDue) : null
  );

  return (
    <div className="App-details-bar px-2 py-2 w-100">
      <DropdownButton
        className="RightMargin"
        as={ButtonGroup}
        id="dropdown-date-picker"
        title={dateDue ? formatDate(moment(dateDue)) : "Due date"}
        drop="up"
        size="sm"
      >
        <DatePicker
          date={dateDue ? moment(dateDue) : null}
          showButtons={true}
          onChange={(date) =>
            dispatch(
              updateTasks(
                tasks.map((item) => {
                  const changedItem = {
                    ...item,
                    dateDue: formatDateDb(date),
                  };
                  if (!date) {
                    changedItem.repeat = null;
                    changedItem.reminders = null;
                  } else {
                    if (item.repeat) {
                      changedItem.repeat = {
                        ...item.repeat,
                        originalDueDate: item.dateDue,
                      };
                    }
                    changedItem.reminders = updateRemindersIfNeeded(item, date);
                  }
                  return changedItem;
                })
              )
            )
          }
        />
        {tasks.length === 1 ? <TaskRepeat task={tasks[0]} /> : null}
      </DropdownButton>

      <SelectableDropdown
        title={project?.name ?? "Project"}
        emptyName="No project"
        items={allProjects}
        onSelect={(cloudId) =>
          dispatch(
            updateTasks(
              tasks.map((item) => ({
                ...item,
                projectCloudId: cloudId,
              }))
            )
          )
        }
      />
      <SelectableDropdown
        title={context?.name ?? "Context"}
        emptyName="No context"
        items={allContexts}
        onSelect={(cloudId) =>
          dispatch(
            updateTasks(
              tasks.map((item) => ({
                ...item,
                contextCloudId: cloudId,
              }))
            )
          )
        }
      />

      <TagsDropdown tasks={tasks} allTags={allTags} />
      <span className="RightMargin">
        {tags.length > 0
          ? tags.map((tag, index) => (
              <TagItem
                key={tag.cloudId}
                tag={tag}
                dispatchFunction={(state, action) =>
                  updateTasks(
                    tasks.map((item) => ({
                      ...item,
                      tags: removeItemFromArray(
                        [...item.tags],
                        tag.cloudId,
                        (item1, item2) => item1 === item2
                      ),
                    }))
                  )
                }
              />
            ))
          : null}
      </span>
    </div>
  );
};

export default DetailsFooter;
