import React from "react";
import "./styles/react_bootstrap_overrides.scss";
import "./App.scss";
import { Theme as ThemeLight } from "./styles/theme_light";
import { Theme as ThemeDark } from "./styles/theme_dark";
import { useSelector } from "react-redux";
import Login from "./features/auth/Login";
import { Content } from "./Content";
import { selectToken } from "./redux/authSlice";
import { selectPrefsThemeIsDark, selectPrefsAccent } from "./redux/prefsSlice";

import moment from "moment";
import "moment/locale/pt-br";
import "moment/locale/de";
import "moment/locale/ru";
import "moment/locale/en-gb";

export function App() {
  var locale = navigator.language || navigator.userLanguage;
  if (locale) {
    locale = locale.toLowerCase();
    if (locale.includes("ru")) {
      moment.locale("ru");
    } else if (locale.includes("de")) {
      moment.locale("de");
    } else if (locale.includes("br")) {
      moment.locale("pt-br");
    } else if (locale.includes("us") || locale.includes("ca")) {
      moment.locale("en");
    } else {
      moment.locale("en-gb");
    }
  } else {
    moment.locale("en-gb");
  }

  const isDarkTheme = useSelector(selectPrefsThemeIsDark);
  const accent = useSelector(selectPrefsAccent);

  for (const [key, value] of Object.entries(
    isDarkTheme ? ThemeDark : ThemeLight
  )) {
    document.documentElement.style.setProperty(key, value);
  }

  document.documentElement.style.setProperty("--accent", accent);

  const token = useSelector(selectToken);

  if (token) {
    return <Content />;
  } else {
    return <Login />;
  }
}
