import React from "react";
import TagItem from "../items/TagItem";
import { updateTasks } from "../../../redux/dataSlice";
import { DropdownButton, ButtonGroup } from "react-bootstrap";

const TagsDropdown = ({ tasks, allTags }) => (
  <DropdownButton
    className="RightMargin"
    as={ButtonGroup}
    id="dropdown-tags"
    title="Tags"
    drop="up"
    size="sm"
  >
    <div className="p-2">
      {allTags.map((tag) => (
        <TagItem
          key={tag.cloudId}
          tag={tag}
          dispatchFunction={(state, action) =>
            updateTasks(
              tasks.map((item) => ({
                ...item,
                tags: item.tags ? [...item.tags, tag.cloudId] : [tag.cloudId],
              }))
            )
          }
        />
      ))}
    </div>
  </DropdownButton>
);

export default TagsDropdown;
