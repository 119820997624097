import ReconnectingWebSocket from "reconnecting-websocket";

const storage = window.localStorage;

const WS = (
  URL,
  isAutoconnect,
  onWelcomeReceived,
  onConnectionChanged,
  onSyncMessage,
  onLogout
) => {
  const rws = new ReconnectingWebSocket(() => URL, [], {
    maxReconnectionDelay: 60000,
    // minReconnectionDelay: 3000,
    startClosed: !isAutoconnect,
    // reconnectionDelayGrowFactor: 1,
  });

  rws.addEventListener("open", (event) => {
    console.log("ws: open", event);
    onConnectionChanged(true);
  });

  rws.addEventListener("message", (event) => {
    console.log("ws: message", event);
    onMessage(event.data);
  });

  rws.addEventListener("close", (event) => {
    console.log("ws: close", event);
    if (event.reason === "need_auth") {
      onLogout();
    } else if (!event.wasClean) {
      onConnectionChanged(false);
    }
  });

  rws.addEventListener("error", (event) => {
    console.log("ws: error", event);
    onConnectionChanged(false);
  });

  // -----------------------------
  const onMessage = (data) => {
    const message = JSON.parse(data);
    switch (message.event) {
      case "blitz:welcome":
        onWelcome(message.data?.profile);
        break;
      //no break here, move forward
      case "blitz:sync_get":
        if (message.data.done === true) {
          storage.setItem("last_sync", message.data.syncDate);
          storage.setItem("last_sync_filters", message.data.syncDate);
        }
      case "blitz:sync": // eslint-disable-line no-fallthrough
        onSyncMessage(message.data);
        break;
      default:
        // console.log("ws unknown event, ignoring");
        break;
    }
  };

  function onWelcome(profile) {
    onWelcomeReceived(profile);

    const lastSync = storage.getItem("last_sync");
    //TODO remove this
    const lastSyncFilters = storage.getItem("last_sync_filters");
    sendMessage(
      rws,
      lastSync
        ? {
            event: "blitz:sync_get",
            data: {
              syncDate: lastSync,
              syncDateFilters: lastSyncFilters,
            },
          }
        : {
            event: "blitz:sync_get",
          }
    );
  }

  return rws;
};

function sendMessage(socket, message) {
  socket.send(JSON.stringify(message));
}

export const sendDataToServer = (socket, toSync) => {
  if (
    toSync.tasks?.length ||
    toSync.projects?.length ||
    toSync.contexts?.length ||
    toSync.tags?.length ||
    toSync.filters?.length
  ) {
    sendMessage(socket, {
      event: "blitz:sync",
      data: { ...toSync },
    });
  }
};

export default WS;
