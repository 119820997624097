import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import {
  filterSetInbox,
  filterSetProject,
  filterSetContext,
  filterAddTag,
  filterClear,
  filterSetDateRange,
  filterSetUserFilter,
} from "../../redux/filterSlice";
import {
  InboxRounded,
  TodayRounded,
  AllInboxRounded,
  AddOutlined as AddIcon,
  CheckCircleRounded as CompletedIcon,
  FlashOnRounded as UserFilterIcon,
  LabelOutlined,
  AlternateEmailRounded,
} from "@material-ui/icons";
import ProjectIcon from "./icons/ProjectIcon";
import ExpandIcon from "./icons/ExpandIcon";
import StarIcon from "./icons/StarIcon";
import { Collapse } from "react-bootstrap";
import {
  selectAllUserFilters,
  selectAllContexts,
  selectAllProjects,
  selectAllTags,
  updateUserFilter,
  updateProject,
  updateContext,
  updateTag,
} from "../../redux/dataSlice";
import DateRange from "../../utils/dateRange";
import "../../utils/extensions";
import {
  setAddingEntity,
  setNavOpen,
  selectEditingEntity,
  selectAddingProject,
  selectAddingContext,
  selectAddingTag,
  selectAppStateNavOpen,
} from "../../redux/appStateSlice";
import { selectProfile } from "../../redux/authSlice";

import TagItem from "./items/TagItem";
import EditableEntityItem from "./items/EditableEntityItem";
import EditTagItem from "./items/EditTagItem";
import Constants from "../../utils/constants";
import { getRankBetween, rankSort } from "../../utils/utils";
import { showPremium } from "../../redux/alertSlice";

const BaseListItem = (props) => {
  const dispatch = useDispatch();

  const onClicked = () => {
    if (props.dispatchFunction && props.item) {
      dispatch(props.dispatchFunction(props.item));
    }
  };

  return (
    <div className="list-group-item-action" onClick={onClicked}>
      {props.children}
    </div>
  );
};

const SortableEntityItem = SortableElement((props) => {
  const value = props.value;

  // const key =
  //   "key:" + value.position + props.task.extra + props.task.subtasks.length;

  return (
    <BaseListItem
      key={value.cloudId}
      item={value}
      dispatchFunction={props.dispatchFunction}
    >
      <EditableEntityItem
        entity={value}
        icon={props.icon}
        dispatchDoneFunction={props.dispatchDoneFunction}
        isEditing={value.cloudId === props.editingCloudId}
        className="Secondary"
        notEditable={props.notEditable}
        style={{ color: value.color }}
      />
    </BaseListItem>
  );
});

const SortableTagItem = SortableElement((props) => {
  return <TagItem {...props} />;
});

const SortableRankList = SortableContainer((props) => {
  return (
    <ul style={{ paddingInlineStart: "0" }}>
      {[...props.items]
        .sort(rankSort)
        .map((value, index) => props.buildChild(value, index))}
    </ul>
  );
});

const Nav = () => {
  const dispatch = useDispatch();

  const allUserFilters = useSelector(selectAllUserFilters);
  const allProjects = useSelector(selectAllProjects);
  const allContexts = useSelector(selectAllContexts);
  const allTags = useSelector(selectAllTags);

  const editingEntity = useSelector(selectEditingEntity);

  const addingProject = useSelector(selectAddingProject);
  const addingContext = useSelector(selectAddingContext);
  const addingTag = useSelector(selectAddingTag);

  const navOpenState = useSelector(selectAppStateNavOpen);
  const profile = useSelector(selectProfile);

  const [isDragging, setIsDragging] = useState(false);

  const sortableRankList = (axis, items, dispatchOnSortEnd, buildChild) => {
    return (
      <SortableRankList
        items={items}
        distance={5}
        axis={axis}
        editingCloudId={editingEntity?.cloudId}
        lockOffset={["0%", "0%"]}
        buildChild={buildChild}
        onSortStart={({ node, index, collection, isKeySorting }, event) =>
          setIsDragging(true)
        }
        onSortEnd={({ oldIndex, newIndex }) => {
          setIsDragging(false);
          if (oldIndex !== newIndex) {
            const draggedItem = items[oldIndex];
            var newRank;
            if (newIndex == 0) {
              newRank = getRankBetween(null, items[newIndex].rank);
            } else if (newIndex == items.length - 1) {
              newRank = getRankBetween(items[newIndex].rank, null);
            } else if (newIndex < oldIndex) {
              newRank = getRankBetween(
                items[newIndex - 1].rank,
                items[newIndex].rank
              );
            } else {
              newRank = getRankBetween(
                items[newIndex].rank,
                items[newIndex + 1].rank
              );
            }
            dispatch(dispatchOnSortEnd({ ...draggedItem, rank: newRank }));
          }
        }}
      />
    );
  };

  return (
    <div className="App-nav p-2">
      {filters()}
      <div style={{ marginTop: "1em", paddingLeft: "0.2em" }}>
        {profile &&
        profile.email &&
        Constants.test_users.includes(profile.email)
          ? userFilters()
          : null}
        {projects()}
        {contexts()}
        {tags()}
      </div>
    </div>
  );

  function filters() {
    return (
      <>
        <div
          className="list-group-item-filter Pointer MenuItem noselect d-flex align-items-center"
          onClick={() => dispatch(filterClear())}
        >
          <AllInboxRounded className="Icon" />
          <div>All</div>
        </div>
        <div
          className="list-group-item-filter Pointer MenuItem noselect d-flex align-items-center"
          onClick={() => dispatch(filterSetDateRange(DateRange.today))}
        >
          <TodayRounded className="Icon" />
          <div>Today</div>
        </div>
        <div
          className="list-group-item-filter Pointer MenuItem noselect d-flex align-items-center"
          onClick={() => dispatch(filterSetInbox())}
        >
          <InboxRounded className="Icon" />
          <div>Inbox</div>
        </div>
        <div
          className="list-group-item-filter Pointer MenuItem noselect d-flex align-items-center"
          onClick={() => dispatch(filterSetDateRange(DateRange.starred))}
        >
          <StarIcon className="Icon" isStarred />
          <div>Starred</div>
        </div>
        <div
          className="list-group-item-filter Pointer MenuItem noselect d-flex align-items-center"
          onClick={() => dispatch(filterSetDateRange(DateRange.completed))}
        >
          <CompletedIcon className="Icon" />
          <div>Completed</div>
        </div>
      </>
    );
  }

  function userFilters() {
    return (
      <div className="nav-collapse Nav-list my-1">
        <div
          className="nav-collapse-item Pointer d-flex align-items-center noselect mb-2 pl-2"
          onClick={() =>
            dispatch(
              setNavOpen({
                id: Constants.id_adding_user_filters,
                value: !navOpenState.userFiltersOpen,
              })
            )
          }
          aria-controls="all-userFilters-list"
          aria-expanded={navOpenState.userFiltersOpen}
        >
          <UserFilterIcon className="" style={{ fontSize: 22 }} />
          Blitz Filters
          {/* <AddIcon
            className="ml-auto"
            onClick={(event) => {
              event.stopPropagation();
              if (
                checkLimitAndPremium(
                  allUserFilters.length,
                  Constants.free_user_filters,
                  "Free Blitz Filters limit reached"
                )
              ) {
                dispatch(
                  setAddingEntity({
                    id: Constants.id_adding_user_filters,
                    rank: getRankBetween(null, allUserFilters[0]?.rank),
                  })
                );
              }
            }}
          /> */}
          <ExpandIcon
            className="ml-1 ml-auto"
            isExpanded={navOpenState.userFiltersOpen}
          />
        </div>
        <Collapse in={navOpenState.userFiltersOpen}>
          <div id="all-userFilters-list">
            {
              allUserFilters.length > 0 ? (
                <ul>
                  {sortableRankList(
                    "y",
                    allUserFilters,
                    updateUserFilter,
                    (value, index) => (
                      <SortableEntityItem
                        key={value.cloudId}
                        index={index}
                        value={value}
                        style={{ textColor: value.color }}
                        icon={
                          <UserFilterIcon
                            style={{
                              marginRight: "5px",
                              fontSize: 18,
                              color: value.color,
                            }}
                          />
                        }
                        notEditable
                        isDragging={isDragging}
                        // editingCloudId={editingEntity?.cloudId}
                        dispatchDoneFunction={() => {}}
                        dispatchFunction={filterSetUserFilter}
                      />
                    )
                  )}
                </ul>
              ) : null
              // <span>No filters</span>
            }
          </div>
        </Collapse>
      </div>
    );
  }

  function projects() {
    return (
      <div className="nav-collapse Nav-list my-1">
        <div
          className="nav-collapse-item Pointer d-flex align-items-center noselect mb-2"
          onClick={() =>
            dispatch(
              setNavOpen({
                id: Constants.id_adding_project,
                value: !navOpenState.projectsOpen,
              })
            )
          }
          aria-controls="all-projects-list"
          aria-expanded={navOpenState.projectsOpen}
        >
          <ProjectIcon className="Icon" style={{ fontSize: 18 }} />
          Projects
          <AddIcon
            className="ml-auto"
            onClick={(event) => {
              event.stopPropagation();
              if (
                checkLimitAndPremium(
                  allProjects.length,
                  Constants.free_projects,
                  "Free active Projects limit reached"
                )
              ) {
                dispatch(
                  setAddingEntity({
                    id: Constants.id_adding_project,
                    rank: getRankBetween(null, allProjects[0]?.rank),
                  })
                );
              }
            }}
          />
          <ExpandIcon className="ml-1" isExpanded={navOpenState.projectsOpen} />
        </div>
        <Collapse in={navOpenState.projectsOpen}>
          <div id="all-projects-list">
            <ul>
              {addingProject != null ? (
                <EditableEntityItem
                  entity={addingProject}
                  dispatchDoneFunction={updateProject}
                  isEditing={true}
                />
              ) : null}
              {sortableRankList(
                "y",
                allProjects,
                updateProject,
                (value, index) => (
                  <SortableEntityItem
                    key={value.cloudId}
                    index={index}
                    value={value}
                    icon={
                      <ProjectIcon
                        style={{ marginRight: "5px", fontSize: 18 }}
                      />
                    }
                    isDragging={isDragging}
                    editingCloudId={editingEntity?.cloudId}
                    dispatchDoneFunction={updateProject}
                    dispatchFunction={filterSetProject}
                  />
                )
              )}
            </ul>
          </div>
        </Collapse>
      </div>
    );
  }

  function contexts() {
    return (
      <div className="nav-collapse Nav-list my-1">
        <div
          className="nav-collapse-item Pointer d-flex align-items-center noselect mb-2"
          onClick={() =>
            dispatch(
              setNavOpen({
                id: Constants.id_adding_context,
                value: !navOpenState.contextsOpen,
              })
            )
          }
          aria-controls="example-contexts-text"
          aria-expanded={navOpenState.contextsOpen}
        >
          <AlternateEmailRounded className="Icon" style={{ fontSize: 18 }} />
          Contexts
          <AddIcon
            className="ml-auto"
            onClick={(event) => {
              event.stopPropagation();
              if (
                checkLimitAndPremium(
                  allContexts.length,
                  Constants.free_contexts,
                  "Free Contexts limit reached"
                )
              ) {
                dispatch(
                  setAddingEntity({
                    id: Constants.id_adding_context,
                    rank: getRankBetween(null, allContexts[0]?.rank),
                  })
                );
              }
            }}
          />
          <ExpandIcon className="ml-1" isExpanded={navOpenState.contextsOpen} />
        </div>
        <Collapse in={navOpenState.contextsOpen}>
          <div id="all-contexts-list">
            <ul>
              {addingContext != null ? (
                <EditableEntityItem
                  entity={addingContext}
                  dispatchDoneFunction={updateContext}
                  isEditing={true}
                />
              ) : null}

              {sortableRankList(
                "y",
                allContexts,
                updateContext,
                (value, index) => (
                  <SortableEntityItem
                    key={value.cloudId}
                    index={index}
                    value={value}
                    icon={<AlternateEmailRounded style={{ fontSize: 18 }} />}
                    isDragging={isDragging}
                    editingCloudId={editingEntity?.cloudId}
                    dispatchDoneFunction={updateContext}
                    dispatchFunction={filterSetContext}
                  />
                )
              )}
            </ul>
          </div>
        </Collapse>
      </div>
    );
  }

  function tags() {
    return (
      <div className="nav-collapse Nav-list my-1">
        <div
          className="nav-collapse-item Pointer d-flex align-items-center noselect mb-2"
          onClick={() =>
            dispatch(
              setNavOpen({
                id: Constants.id_adding_tag,
                value: !navOpenState.tagsOpen,
              })
            )
          }
          aria-controls="example-tags-text"
          aria-expanded={navOpenState.tagsOpen}
        >
          <LabelOutlined className="Icon" style={{ fontSize: 18 }} />
          Tags
          <AddIcon
            className="ml-auto"
            onClick={(event) => {
              event.stopPropagation();
              if (
                checkLimitAndPremium(
                  allTags.length,
                  Constants.free_tags,
                  "Free Tags limit reached"
                )
              ) {
                dispatch(
                  setAddingEntity({
                    id: Constants.id_adding_tag,
                    rank: getRankBetween(null, allTags[0]?.rank),
                    color: Constants.default_colors.random(),
                  })
                );
              }
            }}
          />
          <ExpandIcon className="ml-1" isExpanded={navOpenState.tagsOpen} />
        </div>
        <Collapse in={navOpenState.tagsOpen}>
          <div id="all-tags-list">
            {addingTag ? (
              <EditTagItem
                entity={addingTag}
                key={addingTag.cloudId}
                dispatchDoneFunction={updateTag}
              />
            ) : null}
            {sortableRankList("xy", allTags, updateTag, (value, index) => {
              return (
                <SortableTagItem
                  key={value.cloudId}
                  index={index}
                  value={value}
                  tag={value}
                  editable
                  dispatchFunction={filterAddTag}
                />
              );
            })}
          </div>
        </Collapse>
      </div>
    );
  }

  function checkLimitAndPremium(current, max, message) {
    if (current >= max && !profile.isPremium) {
      dispatch(showPremium(message));
      return false;
    }
    return true;
  }
};

export default Nav;
