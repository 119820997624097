import React from "react";
import { useDispatch } from "react-redux";
import { hideAlert } from "../../redux/alertSlice";

export const LoginFooter = ({
  isRegister,
  isRecover,
  setIsRegister,
  setIsRecover,
}) => {
  const dispatch = useDispatch();

  if (isRecover) {
    return (
      <div className="text-center">
        <a
          href={"/login"}
          onClick={(e) => {
            e.preventDefault();
            setIsRecover(false);
            dispatch(hideAlert());
          }}
        >
          Back to Login
        </a>
      </div>
    );
  } else {
    return (
      <div className="text-center">
        {isRegister ? "Already have an account?" : "Don't have an account?"}{" "}
        <a
          href={isRegister ? "/login" : "/register"}
          onClick={(e) => {
            e.preventDefault();
            setIsRegister(!isRegister);
            dispatch(hideAlert());
          }}
        >
          {isRegister ? "Login" : "Register"}
        </a>
      </div>
    );
  }
};
