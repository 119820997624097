import React from "react";

export const Circle = (props) => {
  const { color, ...rest } = props;

  var circleStyle = {
    padding: 10,
    backgroundColor: props.color ?? "transparent",
    borderRadius: "50%",
    width: 44,
    height: 32,
  };
  return <div {...rest} style={circleStyle}></div>;
};
