import React, { useState } from "react";
import PropTypes from "prop-types";
import TagItem from "./TagItem";
import moment from "moment";
import StarIcon from "../icons/StarIcon";
import DueDateIcon from "../icons/DueDateIcon";
import { Checkbox } from "pretty-checkbox-react";
import {
  PlaylistAddCheckRounded as SubtasksIcon,
  CheckRounded as CheckboxIcon,
  DescriptionRounded as DescriptionIcon,
} from "@material-ui/icons";

import {
  filterSetProject,
  filterSetContext,
  filterAddTag,
} from "../../../redux/filterSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  completeTask,
  selectAllProjects,
  selectAllContexts,
  selectAllTags,
} from "../../../redux/dataSlice";

import { findByCloudId, findByCloudIds } from "../../../utils/utils";
import { formatDate } from "../../../utils/dateUtils";
import { ListGroup, Container } from "react-bootstrap";
import ContextItem from "./ContextItem";
import ProjectItem from "./ProjectItem";

import "@djthoms/pretty-checkbox";

const TaskItem = ({ task, isSelected, onSelected }) => {
  const dispatch = useDispatch();

  const project = useSelector((state) =>
    findByCloudId(selectAllProjects(state), task.projectCloudId)
  );
  const context = useSelector((state) =>
    findByCloudId(selectAllContexts(state), task.contextCloudId)
  );
  const tags = useSelector((state) =>
    findByCloudIds(selectAllTags(state), task.tags)
  );

  const [isChecked, setChecked] = useState(task.completed);
  const [isLocked, setLocked] = useState(false);

  const onChecked = (event) => {
    setChecked(!task.completed);
    setLocked(true);
    setTimeout(() => {
      dispatch(completeTask({ ...task }));
    }, 300);
    setTimeout(() => {
      setLocked(false);
    }, 400);
  };

  return (
    <ListGroup.Item
      href={"#" + task.cloudId}
      className={`task Pointer noselect ${isSelected ? "selected-task" : ""}`}
      eventKey={task.cloudId}
      variant="dark"
      style={{
        opacity: task.completed ? 0.7 : 1,
      }}
      onClick={(event) => {
        onSelected(event, task);
      }}
    >
      <Container className="p-0">
        <div className="d-flex align-items-top mb-1">
          {/* Hack to prevent task click and selection */}
          <span onClick={(event) => event.stopPropagation()}>
            <Checkbox
              style={{
                marginLeft: "0.1em",
                marginRight: "0.4em",
              }}
              bigger
              variant="thick"
              shape="round"
              color="success"
              icon={<CheckboxIcon className="svg" data-type="svg" />}
              animation="smooth"
              checked={isLocked ? isChecked : task.completed}
              locked={isLocked}
              onChange={onChecked}
            />
          </span>
          <div
            className={`TaskItemText ${task.completed ? "TaskCompleted" : ""}`}
          >
            {task.name}
          </div>
          {task.description ? (
            <DescriptionIcon className="icon-desc ml-auto Secondary" />
          ) : null}
          {task.starred ? (
            <StarIcon isStarred={true} className="icon-star ml-2 starred" />
          ) : null}
        </div>
        <div className="d-flex align-items-top" style={{ marginLeft: "2.5em" }}>
          <div>
            {task.subtasks?.length > 0 ? (
              <span className="Secondary RightMargin">
                <SubtasksIcon />{" "}
                {task.subtasks.filter((subtask) => subtask.completed).length}/
                {task.subtasks.length}
              </span>
            ) : null}
            {project ? (
              <ProjectItem
                project={project}
                dispatchFunction={filterSetProject}
                className="Secondary"
              />
            ) : null}
            {context ? (
              <ContextItem
                context={context}
                dispatchFunction={filterSetContext}
                className="Secondary"
              />
            ) : null}
            {tags.map((value, index) => (
              <TagItem
                key={value.cloudId}
                tag={value}
                dispatchFunction={filterAddTag}
              />
            ))}
          </div>
          {task.dateDue ? (
            <div
              className={
                "ml-auto center-vertically " +
                (!task.completed &&
                moment().startOf("day").isAfter(moment(task.dateDue))
                  ? "overdue"
                  : "")
              }
            >
              {formatDate(moment(task.dateDue))}
              <DueDateIcon
                isRepeat={task.repeat}
                className="icon-repeat ml-1"
              />
            </div>
          ) : null}
        </div>
      </Container>
    </ListGroup.Item>
  );
};

TaskItem.propTypes = {
  task: PropTypes.object.isRequired,
};

export default TaskItem;
