import moment from "moment";
import { date_format } from "./constants";
import DateRange from "./dateRange";

export const formatDate = (date) => {
  if (!date) {
    return null;
  }

  const now = moment();

  if (
    moment().isSame(date, "day") ||
    moment().add(-1, "day").isSame(date, "day") ||
    moment().add(1, "day").isSame(date, "day")
  ) {
    return date.calendar().split(" ")[0].replace(",", "");
  }

  return date.year() == now.year()
    ? date.format("D MMMM")
    : date.format("D MMMM YYYY");
};

export const formatTime = (dateAsString) => {
  return moment.utc(dateAsString).local().format("HH:mm");
};

export const isToday = (date) => {
  if (!date) {
    return false;
  }

  const now = moment();
  return now.isSame(date, "day");
};

export const isTodayOrBefore = (date) => {
  if (!date) {
    return false;
  }

  const now = moment();
  return now.isSame(date, "day") || date.isBefore(now);
};

export const formatDateDb = (date) => {
  return date?.format(date_format);
};

export const getDateRangeName = (dateRange) => {
  switch (dateRange) {
    case DateRange.all:
      return "All";
    case DateRange.starred:
      return "Starred";
    case DateRange.overdue:
      return "overdue";
    case DateRange.today:
      return "Today";
    case DateRange.tomorrow:
      return "Tomorrow";
    case DateRange.next7Days:
      return "Next 7 days";
    case DateRange.later:
      return "Later";
    case DateRange.noDate:
      return "No date";
    case DateRange.completed:
      return "Completed";
    default:
      return "";
  }
};

export const getDateRange = (task, onlyDate) => {
  if (!onlyDate) {
    if (task.completed) {
      return DateRange.completed;
    }

    if (task.starred) {
      return DateRange.starred;
    }
  }

  if (task.dateDue == null) {
    return DateRange.noDate;
  } else {
    const now = moment();
    const dueMoment = moment(task.dateDue);

    if (now.isSame(dueMoment, "date") || dueMoment.isBefore(now)) {
      return DateRange.today;
    } else {
      const start = now.startOf("day").add(1, "d");
      const end = moment().startOf("day").add(7, "d");

      if (now.isSame(dueMoment, "date")) {
        return DateRange.tomorrow;
      } else if (dueMoment.isAfter(start) && dueMoment.isBefore(end)) {
        return DateRange.next7Days;
      } else {
        return DateRange.later;
      }
    }
  }
};
