import { createSlice } from "@reduxjs/toolkit";
import "../utils/extensions";
import "../utils/constants";
import {
  id_adding_project,
  id_adding_context,
  id_adding_tag,
  id_adding_user_filters,
} from "../utils/constants";

const storage = window.localStorage;

const state = {
  addingEntity: null,
  editingEntity: null,
  navOpen: storage.getObject("app_state_nav_open") ?? {
    userFiltersOpen: true,
    projectsOpen: true,
    contextsOpen: true,
    tagsOpen: true,
  },
};

export const appStateSlice = createSlice({
  name: "appState",
  initialState: state,
  reducers: {
    setEditingEntity: (state, action) => {
      clear(state);
      state.editingEntity = action.payload;
    },
    setAddingEntity: (state, action) => {
      clear(state);
      state.addingEntity = action.payload;
    },
    setNavOpen: (state, action) => {
      if (action.payload.id === id_adding_project) {
        state.navOpen.projectsOpen = action.payload.value === true;
      } else if (action.payload.id === id_adding_context) {
        state.navOpen.contextsOpen = action.payload.value === true;
      } else if (action.payload.id === id_adding_tag) {
        state.navOpen.tagsOpen = action.payload.value === true;
      } else if (action.payload.id === id_adding_user_filters) {
        state.navOpen.userFiltersOpen = action.payload.value === true;
      }
      storage.setObject("app_state_nav_open", state.navOpen);
    },
  },
});

function clear(state) {
  state.addingEntity = null;
  state.editingEntity = null;
}

export const selectAddingProject = (state) =>
  state.appState.addingEntity?.id === id_adding_project
    ? state.appState.addingEntity
    : null;
export const selectAddingContext = (state) =>
  state.appState.addingEntity?.id === id_adding_context
    ? state.appState.addingEntity
    : null;
export const selectAddingTag = (state) =>
  state.appState.addingEntity?.id === id_adding_tag
    ? state.appState.addingEntity
    : null;

export const selectEditingEntity = (state) => state.appState.editingEntity;

export const selectAppStateNavOpen = (state) => state.appState.navOpen;

export const { setAddingEntity, setEditingEntity, setNavOpen } =
  appStateSlice.actions;

export default appStateSlice.reducer;
