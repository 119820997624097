import React from "react";
import { Dropdown, DropdownButton, ButtonGroup } from "react-bootstrap";

const SelectableDropdown = ({ title, emptyName, items, onSelect }) => (
  <DropdownButton
    className="RightMargin"
    as={ButtonGroup}
    id={`dropdown-${title}`}
    title={title}
    drop="up"
    size="sm"
  >
    {items.map((item) => (
      <Dropdown.Item
        key={item.cloudId}
        eventKey={item.cloudId}
        onSelect={onSelect}
      >
        {item.name}
      </Dropdown.Item>
    ))}
    <Dropdown.Divider />
    <Dropdown.Item onSelect={(_) => onSelect(null)}>{emptyName}</Dropdown.Item>
  </DropdownButton>
);

export default SelectableDropdown;
