import React from "react";
import { useDispatch } from "react-redux";
import StarIcon from "../icons/StarIcon";
import { ArrowBackRounded, DeleteRounded } from "@material-ui/icons";

import { selectTask, updateTasks } from "../../../redux/dataSlice";

const DetailsNavbar = ({ tasks }) => {
  const dispatch = useDispatch();

  const isStarred = tasks.every((item) => item.starred);

  return (
    <div className="App-details-bar d-flex px-2 py-2">
      <ArrowBackRounded
        className="Pointer"
        onClick={() => dispatch(selectTask(null))}
      />

      <StarIcon
        className={"Pointer ml-auto mr-3 " + (isStarred ? "starred" : "")}
        isStarred={isStarred}
        onClick={() => {
          dispatch(
            updateTasks(tasks.map((item) => ({ ...item, starred: !isStarred })))
          );
        }}
      />
      {tasks[0]?.cloudId ? (
        <DeleteRounded
          className="Pointer"
          onClick={() => {
            dispatch(
              updateTasks(tasks.map((item) => ({ ...item, deleted: true })))
            );
            dispatch(selectTask(null));
          }}
        />
      ) : null}
    </div>
  );
};

export default DetailsNavbar;
