import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { prefsSetTheme, selectPrefsThemeIsDark } from "../../redux/prefsSlice";
import LightThemeIcon from "./icons/LightThemeIcon";
import DarkThemeIcon from "./icons/DarkThemeIcon";
import Switch from "react-switch";

const ThemeSwitch = (props) => {
  const isDarkTheme = useSelector(selectPrefsThemeIsDark);
  const dispatch = useDispatch();

  const changeTheme = (_) =>
    dispatch(prefsSetTheme(isDarkTheme ? "light" : "dark"));

  return (
    <Switch
      {...props}
      checked={isDarkTheme}
      checkedIcon={<DarkThemeIcon color="white" className="ml-1" />}
      uncheckedIcon={<LightThemeIcon color="white" className="ml-1" />}
      offColor={"#fbc02d"}
      onColor={"#5c6bc0"}
      width={60}
      height={28}
      onChange={changeTheme}
    />
  );
};

export default ThemeSwitch;
