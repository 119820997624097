export const Theme = {
  "--accent": "#FFC107",
  "--background-color": "#24272E",
  "--background-color-lighter": "#31343D",
  "--background-color-darker": "#212529",
  "--background-color-very-darker": "#151515",
  "--text-color": "#ffffff",
  "--text-color-secondary": "#aaaaaa",

  "--input-border": "rgba(0, 0, 0, 0.25)",
  "--input-background": "rgba(0, 0, 0, 0.15)",
  "--input-border-focus": "rgba(0, 0, 0, 0.5)",
  "--input-background-focus": "rgba(0, 0, 0, 0.25)",
};
