import React from "react";
import { EditRounded } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { setAddingEntity } from "../../../redux/appStateSlice";
import { id_adding_tag } from "../../../utils/constants";
import { isColorLight } from "../../../utils/utils";

const TagItem = ({ tag, editable, dispatchFunction }) => {
  const dispatch = useDispatch();

  const onClick = (event) => {
    if (dispatchFunction) {
      event.stopPropagation();
      dispatch(dispatchFunction(tag));
    }
  };

  return tag ? (
    <span
      onClick={onClick}
      className={`noselect badge badge-pill badge-primary ${
        !editable ? "pb-1" : "pt-1"
      }`}
      style={{
        backgroundColor:
          !tag.color || tag.color === "" ? "transparent" : tag.color,
        marginRight: 10,
        cursor: "pointer",
        color: isColorLight(tag.color) ? "black" : "white",
      }}
    >
      {tag.name}
      {editable ? (
        <EditRounded
          style={{ fontSize: 14, marginLeft: 8, paddingBottom: 1 }}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setAddingEntity({ ...tag, id: id_adding_tag }));
          }}
        />
      ) : null}
    </span>
  ) : null;
};

TagItem.propTypes = {
  // tag: PropTypes.object.isRequired,
};

export default TagItem;
