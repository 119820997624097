export const Theme = {
  "--accent": "#FFC107",
  "--background-color": "#eee",
  "--background-color-lighter": "#f5f5f5",
  "--background-color-darker": "#ccc",
  "--background-color-very-darker": "#aaa",
  "--text-color": "#444",
  "--text-color-secondary": "#666",

  "--input-border": "rgba(128, 128, 128, 0.25)",
  "--input-background": "rgba(255, 255, 255, 0.15)",
  "--input-border-focus": "rgba(128, 128, 128, 0.5)",
  "--input-background-focus": "rgba(255, 255, 255, 0.25)",
};
