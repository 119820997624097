import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { SortableHandle } from "react-sortable-hoc";
import {
  CloseRounded as DeleteSubtaskIcon,
  DragHandle as DragIcon,
} from "@material-ui/icons";
import TextareaAutosize from "react-textarea-autosize";
import { updateTask } from "../../../redux/dataSlice";
import { removeItemFromArray } from "../../../utils/utils";
import constants from "../../../utils/constants";

const SortableItem = SortableHandle((_) => (
  <DragIcon className="ml-3 mr-2 Move" />
));

const SubtaskItem = (props) => {
  const task = props.task;
  const subtask = props.subtask;
  const createNewSubtask = props.createNewSubtask;
  const formRef = props.form;

  const dispatch = useDispatch();

  const [name, setName] = useState(subtask.name ?? "");

  const titleRef = useRef(null);
  const [cursor, setCursor] = useState(null);

  useEffect(() => {
    const input = titleRef.current;
    if (input && name)
      input.setSelectionRange(cursor ?? name.length, cursor ?? name.length);
  }, [titleRef]);

  const onChecked = (event) => {
    if (event) {
      event.stopPropagation();
    }
    updateSubtask({
      ...subtask,
      name: name,
      completed: !subtask.completed,
    });
  };

  const refreshTimeout = useRef(null);

  const handleTimer = (name) => {
    clearTimeout(refreshTimeout.current);
    refreshTimeout.current = setTimeout(() => {
      refreshTimeout.current = null;
      if (subtask.name !== name) {
        updateSubtask({ ...subtask, name: name });
      }
    }, constants.refresh_subtask_when_typing_delay);
  };

  function updateSubtask(updatedSubtask) {
    const withRemovedSubtask = removeItemFromArray(
      [...task.subtasks],
      subtask,
      (item1, item2) => item1.position === item2.position
    );

    if (!updatedSubtask) {
      for (var i = 0; i < withRemovedSubtask.length; i++) {
        var item = withRemovedSubtask[i];
        if (item.position >= subtask.position) {
          withRemovedSubtask[i] = {
            ...item,
            position: item.position - 1,
            isNew: i === 0 && subtask.position === 1,
          };
        } else if (item.position === subtask.position - 1) {
          withRemovedSubtask[i] = { ...item, isNew: true };
        }
      }
    }
    dispatch(
      updateTask({
        ...task,
        subtasks: (updatedSubtask
          ? [...withRemovedSubtask, updatedSubtask]
          : withRemovedSubtask
        ).sort((a, b) => a.position - b.position),
      })
    );
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      //enter
      event.preventDefault();
      if (event.ctrlKey) {
        onChecked(null);
      } else {
        focusNext(true);
      }
    } else if (event.keyCode === 8) {
      // backspace
      if (name.length === 0) {
        event.preventDefault();
        deleteSubtask();
      }
    }
  }

  function focusNext(canCreateNew) {
    if (!formRef.current) {
      return;
    }
    const nextIndex = subtask.position * 2 + 1;
    //focus next input field (+2 because of the checkbox which is also an input)
    console.log("--- next focus index: ", nextIndex);
    if (nextIndex <= formRef.current.elements.length) {
      formRef.current.elements[nextIndex].focus();
    } else if (canCreateNew) {
      const newSubtask = createNewSubtask(false);
      if (newSubtask) {
        if (refreshTimeout.current) {
          clearTimeout(refreshTimeout.current);
          refreshTimeout.current = null;
        }
        dispatch(
          updateTask({
            ...task,
            subtasks: [
              ...task.subtasks.slice(0, task.subtasks.length - 1),
              { ...subtask, name: name },
              newSubtask,
            ],
          })
        );
      }
    }
  }

  function deleteSubtask() {
    if (refreshTimeout.current) {
      clearTimeout(refreshTimeout.current);
      refreshTimeout.current = null;
    }
    updateSubtask(null);
  }

  return (
    <div
      className="subtask d-flex align-items-center"
      style={{ pointerEvents: "all" }}
    >
      <input
        type="checkbox"
        tabIndex={-1}
        aria-label="Complete the task"
        style={{ marginLeft: "0.2em" }}
        checked={subtask.completed === true}
        onChange={onChecked}
      />
      <div
        className={`TaskItemText ${subtask.completed ? "TaskCompleted" : ""}`}
      >
        <TextareaAutosize
          minRows={1}
          maxRows={5}
          value={name ?? ""}
          autoFocus={subtask.isNew}
          type="text"
          ref={titleRef}
          maxLength="500"
          placeholder="Subtask"
          className="my-1 w-100"
          style={{ resize: "none" }}
          onChange={(e) => {
            setCursor(e.target.selectionStart);
            const newName = e.target.value;
            setName(newName);
            handleTimer(newName);
          }}
          onKeyDown={handleKeyDown}
        />
      </div>
      <DeleteSubtaskIcon
        className="Pointer"
        onClick={(_) => {
          deleteSubtask();
        }}
      />
      <SortableItem />
    </div>
  );
};

export default SubtaskItem;
