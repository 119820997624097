import React from "react";
import { useDispatch } from "react-redux";
import { Button, Row, Col, Container } from "react-bootstrap";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";
import { Facebook } from "@material-ui/icons";
import { showAlert } from "../../redux/alertSlice";
import { getErrorMessage } from "../../utils/api";

export const SocialForm = ({ onLogin, deviceId }) => {
  const dispatch = useDispatch();

  const responseFacebook = (response) => {
    if (response.accessToken) {
      loginSocial("facebook", response.accessToken);
    } else {
      //TODO show error
    }
  };

  const responseGoogle = (response) => {
    if (response.tokenId) {
      loginSocial("google", response.tokenId);
    } else {
      //TODO show error
    }
  };

  function loginSocial(name, token) {
    const authData = { network: name, token: token, info: "" };
    const headers = new Headers({
      Origin2: "web@" + deviceId,
    });
    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/social`, {
      method: "POST",
      mode: "cors",
      headers: headers,
      body: JSON.stringify(authData),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.error || !result.token) {
          dispatch(
            showAlert({ text: getErrorMessage(result.error), type: "danger" })
          );
        } else {
          console.log("Logged in with", name, result);
          onLogin(result);
        }
        // window.localStorage.setItem("token", result.token);
        // setTimeout(() => {
        //   setIsLoaded(true);
        // }, 2000);
      })
      .catch((error) => {
        dispatch(showAlert({ text: getErrorMessage(""), type: "danger" }));
      });
  }

  return (
    <Container className="p-0">
      <Row noGutters>
        <Col className="pr-2">
          <GoogleLogin
            clientId="419174467846-p2adb92pdp0l2fbb6ifh64mrcirhlhb0.apps.googleusercontent.com"
            buttonText="Google"
            className="w-100"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            render={(renderProps) => (
              <Button
                className="LoginSocialButton"
                block
                onClick={renderProps.onClick}
                variant="outline-light"
              >
                <img
                  height="24"
                  width="24"
                  className="mr-3"
                  style={{ padding: "2px" }}
                  alt="Logo"
                  src={process.env.PUBLIC_URL + "/g-logo.png"}
                />
                Google
              </Button>
            )}
          />
        </Col>

        <Col className="pl-2">
          <FacebookLogin
            appId="2131877603575721"
            autoLoad={false}
            fields="name,email,picture"
            callback={responseFacebook}
            render={(renderProps) => (
              <Button
                block
                onClick={renderProps.onClick}
                variant="outline-light"
                className="LoginSocialButton"
              >
                <Facebook className="mr-3" style={{ color: "#1877F2" }} />
                Facebook
              </Button>
            )}
          />
        </Col>
      </Row>
    </Container>
  );
};
