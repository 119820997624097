import React from "react";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { selectPrefsThemeIsDark } from "../../../redux/prefsSlice";

const ThemedButton = (props) => {
  const isDarkTheme = useSelector(selectPrefsThemeIsDark);

  return (
    <Button
      {...props}
      variant={isDarkTheme ? "outline-light" : "outline-dark"}
    />
  );
};

export default ThemedButton;
