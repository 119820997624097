export const getErrorMessage = (code) => {
  switch (code) {
    case "wrong_credentials":
      return "Wrong credentials";
    case "already_registered":
      return "User already exists";
    case "invalid_code":
      return "Invalid code";
    case "code_expired":
      return "Code expired";
    case "password_too_short":
      return "Password too short";
    case "password_too_long":
      return "Password too long";
    case "cannot_change":
      return "Invalid code";
    default:
      return "Something wrong. Try again";
  }
};
