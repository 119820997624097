import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { EditRounded, DeleteRounded, CheckRounded } from "@material-ui/icons";
import { useInputValue } from "../../../hooks/useInputValue";
import { filterNotifyEntityDeleted } from "../../../redux/filterSlice";

import {
  setAddingEntity,
  setEditingEntity,
} from "../../../redux/appStateSlice";

const EditableEntityItem = (props) => {
  const { entity, icon, dispatchDoneFunction, isEditing, ...rest } = props;
  const dispatch = useDispatch();

  const nameInput = useInputValue(entity.name);

  const onClick = (event) => {
    if (isEditing) {
      event.stopPropagation();
    }
  };

  const onKeyPress = (event) => {
    if (event.key === "Enter") {
      finishEditingEntity();
    }
  };

  const onKeyUp = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation();
      if (entity.objectId) {
        dispatch(setEditingEntity(null));
      } else {
        dispatch(setAddingEntity(null));
      }
    }
  };

  const finishEditingEntity = () => {
    dispatch(dispatchDoneFunction({ ...entity, name: nameInput.value() }));
    if (!entity.objectId) {
      dispatch(setAddingEntity(null));
    } else {
      dispatch(setEditingEntity(null));
    }
  };

  const onFocus = () => {
    //clear previously entered and not confirmed value
    nameInput.setValue(entity.name);
  };

  return (
    <div
      {...rest}
      className={`Pointer noselect nowrap d-flex align-items-center ${
        rest.className ?? ""
      }`}
      onClick={onClick}
    >
      {!isEditing && icon ? icon : null}
      {isEditing ? (
        <input
          {...nameInput.bind}
          className="w-100"
          type="text"
          autoFocus={isEditing}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          maxLength="30"
        />
      ) : (
        <div>{entity.name}</div>
      )}

      {isEditing ? (
        <div className="ml-auto list-group-item-icon-actions">
          <DeleteRounded
            onClick={(e) => {
              e.stopPropagation();
              if (entity.cloudId) {
                dispatch(dispatchDoneFunction({ ...entity, deleted: true }));
                dispatch(filterNotifyEntityDeleted(entity));
              } else {
                dispatch(setAddingEntity(null));
              }
            }}
          />
          <CheckRounded
            onClick={(e) => {
              e.stopPropagation();
              finishEditingEntity();
            }}
          />
        </div>
      ) : props.notEditable ? null : (
        <EditRounded
          className="ml-auto list-group-item-icon-edit"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setEditingEntity(entity));
          }}
        />
      )}
    </div>
  );
};

EditableEntityItem.propTypes = {
  entity: PropTypes.object.isRequired,
  dispatchDoneFunction: PropTypes.func.isRequired,
};

export default EditableEntityItem;
