import React from "react";
import { useDispatch } from "react-redux";
import ProjectIcon from "../icons/ProjectIcon";

const ProjectItem = (props) => {
  const { project, dispatchFunction, ...rest } = props;
  const dispatch = useDispatch();

  const onClick = (event) => {
    if (dispatchFunction) {
      event.stopPropagation();
      dispatch(dispatchFunction(project));
    }
  };

  return project ? (
    <span
      {...rest}
      className={`RightMargin Pointer noselect nowrap ${rest.className ?? ""}`}
      onClick={onClick}
    >
      <ProjectIcon style={{ marginRight: "5px", fontSize: 18 }} />
      {project.name}
    </span>
  ) : null;
};

ProjectItem.propTypes = {
  // project: PropTypes.object.isRequired,
};

export default ProjectItem;
